import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AuthError } from './errors';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: false, authError: false, errorResponseBody: '' };
  }

  static getDerivedStateFromError(error) {
    if (error instanceof AuthError) {
      return { authError: true };
    }

    return { error: error };
  }

  setErrorResponseBody(x) {
    this.setState({ errorResponseBody: x });
  }

  componentDidCatch(error, info) {
    if (error instanceof AuthError) {
      return;
    }

    console.error(
      'ErrorBoundary.componentDidCatch',
      error,
      info.componentStack
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const error = this.state.error;

    if (
      error &&
      error.response &&
      !error.response.bodyUsed &&
      error.response.text
    ) {
      error.response
        .text()
        .then(this.setErrorResponseBody.bind(this))
        .catch(this.setErrorResponseBody.bind(this));
    }
  }

  render() {
    if (this.state.authError) {
      console.log('C1 ErrorBoundary redirecting to /login');
      window.location.pathname = '/login';
      return <div />;
    }

    if (this.state.error) {
      return (
        <Box>
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '75%',
                md: '50%',
              },
              m: 'auto',
              mt: 5,
              p: 5,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                textAlign: 'center',
                fontSize: (t) => t.typography.pxToRem(30),
              }}
            >
              Something went wrong ...{'\u00A0'}
              <span role="img" aria-label="Face with pleading eyes">
                🥺
              </span>
            </Typography>

            <details>
              <summary style={{ cursor: 'pointer' }}>More</summary>

              <pre>
                {this.state.error.toString()}
                {'\u00A0'}
                <p>{this.state.error.stack}</p>

                {this.state.error.response && (
                  <>
                    {'\u00A0'}
                    <p>{this.state.error.response.status}</p>
                    {'\u00A0'}
                    <p>{this.state.errorResponseBody}</p>
                  </>
                )}
              </pre>
            </details>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
