import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = React.forwardRef(({ size = 80, sx = {} }, ref) => {
  return (
    <Box ref={ref} sx={{ textAlign: 'center', mt: 5, ...sx }}>
      <CircularProgress size={size} />
    </Box>
  );
});

export default Loader;
