import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import Stack from '@mui/material/Stack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Image } from './Image';
import Loader from './Loader';

const FormIconButton = ({ Icon, onClick }) => {
  return (
    <IconButton
      size="small"
      aria-label="One more"
      onClick={onClick}
      sx={{
        width: (t) => t.spacing(7),
        height: (t) => t.spacing(7),
      }}
    >
      <Icon fontSize="medium" />
    </IconButton>
  );
};

const AddButton = ({ onClick }) => {
  return <FormIconButton Icon={AddCircleIcon} onClick={onClick} />;
};

const RemoveButton = ({ onClick }) => {
  return <FormIconButton Icon={RemoveCircleIcon} onClick={onClick} />;
};

function BasicField(props) {
  const {
    id,
    type,
    placeholder,
    label,
    value,
    options,
    onChange: customOnChange,
    verbose,
    gridItemProps,
    ...rest
  } = props.field;

  const shouldHide = props.field.verbose && !props.form.verbose && !props.value;
  const [loading, setLoading] = useState(false);

  rest.sx = { ...rest.sx };

  if (shouldHide) {
    rest.sx.display = 'none';
  }

  rest.sx.width = rest.sx.width || '100%';
  // rest.sx.my = rest.sx.my || type !== 'hidden' ? 1 : undefined;

  function onChange(event) {
    if (type === 'date') {
      let dateValue;
      try {
        dateValue = event.toISOString().split('T')[0];
      } catch (exc) {
        // Invalida date
      }
      props.setValue(dateValue);
    } else if (event.target.files) {
      props.setValue(event.target.files[0]);
    } else if (type === 'checkbox') {
      props.setValue(event.target.checked);
    } else if (type === 'radio') {
      props.setValue(event.target.value);
    } else {
      props.setValue(event.target.value);
    }

    props.cleanErrors();

    if (customOnChange) {
      customOnChange(event, props.form, setLoading);
    }
  }

  let inner;
  if (type === 'checkbox') {
    inner = (
      <>
        <FormControlLabel
          control={
            <Checkbox
              id={id}
              name={id}
              checked={props.value || value || false}
              onChange={onChange}
              {...rest}
            />
          }
          label={label}
        />
      </>
    );
  } else if (type === 'date') {
    inner = (
      <DatePicker
        variant="outlined"
        id={id}
        name={id}
        type={type}
        label={label}
        value={props.value || value || ''}
        onChange={onChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...rest}
              error={!!props.errors}
              helperText={props.errors}
            />
          );
        }}
      />
    );
  } else if (type === 'radio') {
    inner = (
      <>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup id={id} name={id} aria-label={label}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    id={option.value}
                    name={id}
                    onChange={onChange}
                    checked={
                      (props.value && props.value === option.value) ||
                      (!props.value && value === option.value)
                    }
                  />
                }
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  } else if (type === 'select') {
    inner = (
      <FormControl {...rest}>
        <InputLabel id={`${id}-label`} htmlFor={id}>
          {label}
        </InputLabel>
        <Select
          id={id}
          name={id}
          label={label}
          labelId={`${id}-label`}
          value={props.value || value || ''}
          onChange={onChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (type === 'image') {
    const src = props.value || value || '';
    if (!src) {
      inner = <Box sx={{ width: 0, height: 0 }} />;
    } else {
      inner = (
        <Image
          id={id}
          src={src}
          alt={label}
          sx={{
            height: (t) => t.spacing(35),
            objectFit: 'cover',
          }}
        />
      );
    }
  } else {
    inner = (
      <TextField
        variant="outlined"
        id={id}
        name={id}
        type={type}
        label={label}
        value={type === 'file' ? undefined : props.value || value || ''}
        onChange={onChange}
        error={!!props.errors}
        helperText={props.errors}
        InputLabelProps={{
          shrink: type === 'file' ? true : undefined,
        }}
        {...rest}
      />
    );
  }

  return (
    <Stack direction="row" spacing={0} sx={{ my: !shouldHide && 1 }}>
      <Zoom appear={false} in={!shouldHide}>
        {inner}
      </Zoom>

      {loading && <Loader size={40} sx={{ m: 'auto', pl: 2 }} />}

      {props.removeItem && <RemoveButton onClick={props.removeItem} />}

      {props.plusOne && <AddButton onClick={props.plusOne} />}
    </Stack>
  );
}

function ObjectField(props) {
  const field = props.field;

  return (
    <>
      {field.label && (
        <Typography sx={{ width: '100%' }}>{field.label}</Typography>
      )}
      <Fields
        fields={field.properties}
        value={props.value}
        setValue={props.setValue}
        form={props.form}
      />
    </>
  );
}

function ArrayField(props) {
  const field = props.field;
  const [length, setLength] = useState(
    (props.value && props.value.length) || 1
  );

  function getValue(idx) {
    return (props.value || [])[idx];
  }

  function setValue(idx) {
    return (value) => {
      const state = [...(props.value || [])];
      state[idx] = value;
      props.setValue(state);
    };
  }

  function getErrors(idx) {
    return (props.errors || [])[idx];
  }

  function cleanErrors(idx) {
    return () => {
      const errors = [...(props.errors || [])];
      errors[idx] = '';
      props.cleanErrors(errors);
    };
  }

  function getPlusOne(idx) {
    if (idx < length - 1) {
      return null;
    }

    return () => {
      props.setValue([...(props.value || []), undefined]);
      setLength(length + 1);
    };
  }

  function getRemoveItem(idx) {
    if (length === 1) {
      return null;
    }

    return () => {
      props.setValue(props.value.filter((x, _idx) => idx !== _idx));
      setLength(length - 1);
    };
  }

  return (
    <FormControl variant="outlined" fullWidth={true} sx={{ my: 1 }}>
      {field.label && <FormLabel>{field.label}</FormLabel>}

      {[...Array(length)].map((x, idx) => (
        <Fields
          key={idx}
          fields={field.items}
          value={getValue(idx)}
          setValue={setValue(idx)}
          errors={getErrors(idx)}
          cleanErrors={cleanErrors(idx)}
          plusOne={getPlusOne(idx)}
          removeItem={getRemoveItem(idx)}
          form={props.form}
        />
      ))}

      <FormHelperText error>{props.errors}</FormHelperText>
    </FormControl>
  );
}

function Field(props) {
  if (props.field.type === 'array') {
    return ArrayField(props);
  } else if (props.field.type === 'object') {
    return ObjectField(props);
  } else {
    return BasicField(props);
  }
}

const PasswordFieldAdornment = ({ showPassword, setShowPassword }) => {
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  );
};

const usePasswordFieldAdornment = () => {
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordButton = (
    <PasswordFieldAdornment
      showPassword={showPassword}
      setShowPassword={setShowPassword}
    />
  );

  return [showPassword, showPasswordButton];
};

function Fields(props) {
  const [showPassword, showPasswordButton] = usePasswordFieldAdornment();
  props.fields.forEach((field) => {
    if (field.type === 'password' || field._type === 'password') {
      field._type = 'password';
      field.type = showPassword ? 'text' : 'password';
      field.InputProps = {
        endAdornment: showPasswordButton,
      };
    }
  });

  function setValue(key) {
    return (value) => {
      const state = { ...props.value };
      state[key] = value;
      props.setValue(state);
    };
  }

  function cleanErrors(key) {
    return () => {
      const errors = { ...props.errors };
      errors[key] = '';
      props.cleanErrors(errors);
    };
  }

  function getValue(key) {
    return (props.value || {})[key];
  }

  function getErrors(key) {
    return (props.errors || {})[key];
  }

  function getPlusOne(idx) {
    if (idx < props.fields.length - 1) {
      return null;
    }

    return props.plusOne;
  }

  function getRemoveItem(idx) {
    if (idx < props.fields.length - 1) {
      return null;
    }

    return props.removeItem;
  }

  return (
    <Grid container columnSpacing={1}>
      {props.fields.map((field, idx) => (
        <Grid item key={idx} xs={12} {...field.gridItemProps}>
          <Field
            field={field}
            value={getValue(field.id)}
            errors={getErrors(field.id)}
            cleanErrors={cleanErrors(field.id)}
            setValue={setValue(field.id)}
            plusOne={getPlusOne(idx)}
            removeItem={getRemoveItem(idx)}
            form={props.form}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function filterEmptyObject(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => value || value === false || value === '')
      .map(([key, value]) => [key, filterEmpty(value)])
  );
}

function filterEmptyArray(obj) {
  return obj.filter((x) => x);
}

function filterEmpty(obj) {
  if (Array.isArray(obj)) {
    return filterEmptyArray(obj);
  } else if (
    typeof obj === 'object' &&
    obj !== null &&
    !(obj instanceof File)
  ) {
    return filterEmptyObject(obj);
  } else {
    return obj;
  }
}

function formatErrors(response) {
  const errors = { __others__: [] };

  Object.entries(response).forEach(([key, value], idx) => {
    if (['detail', 'non_field_errors', '__others__'].indexOf(key) >= 0) {
      errors['__others__'].push(value);
    } else {
      errors[key] = value;
    }
  });

  return errors;
}

export default function Form({
  fields,
  request,
  submitLabel,
  extraPayload,
  children,
  onSubmit,
  ...rest
}) {
  const { t } = useTranslation();
  const [verbose, setVerbose] = useState(false);

  const fieldIds = fields.map((field) => field.id);

  const [value, setValue] = useState(() =>
    Object.fromEntries(
      Object.entries(rest.value || {}).filter(([key, value]) => {
        return fieldIds.includes(key);
      })
    )
  );
  const [errors, setErrors] = useState({});

  function cleanErrors(errors) {
    errors['__others__'] = '';
    setErrors(errors);
  }

  const defaultOnSubmit = (value) => {
    let payload = filterEmpty(value);

    if (extraPayload) {
      payload = { ...payload, ...extraPayload };
    }

    request.setPayload(payload);
    request.trigger();
  };

  const effectiveOnSubmit = (event) => {
    event.preventDefault();
    (onSubmit || defaultOnSubmit)(value);
  };

  const form = {
    value: value,
    setValue: setValue,
    verbose: verbose,
  };

  const anythingHidden = fields.some((field) => field.verbose);

  useEffect(() => {
    setErrors({});
  }, [request?.response]);

  useEffect(() => {
    if (request?.error?.response?.status) {
      setErrors(formatErrors(request.error?.response?.data));
    }
    if (request?.error && !request.error.response) {
      setErrors({ __others__: request.error.toString() });
    }
  }, [request?.error]);

  const buttonsSx = {
    my: 1,
    width: '100%',
  };

  submitLabel = submitLabel || t('Submit');

  return (
    <Box
      component="form"
      sx={{
        px: 0,
        pt: 0,
        pb: 3,
        width: '100%',
        backgroundColor: 'paper',
      }}
      noValidate
      autoComplete="off"
      onSubmit={effectiveOnSubmit}
      {...rest}
    >
      <Fields
        fields={fields}
        value={value}
        setValue={setValue}
        errors={errors}
        cleanErrors={cleanErrors}
        form={form}
      />

      {anythingHidden && (
        <Button
          variant="outlined"
          onClick={() => setVerbose(!verbose)}
          sx={buttonsSx}
        >
          {verbose ? 'Show less' : 'Show more'}
        </Button>
      )}

      <FormHelperText error>{errors['__others__']}</FormHelperText>

      <LoadingButton
        type="submit"
        variant="contained"
        loading={request?.loading}
        sx={buttonsSx}
      >
        {submitLabel}
      </LoadingButton>

      {children}
    </Box>
  );
}
