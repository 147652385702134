import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getLoggedUser } from './hooks';

const Backend = {
  type: 'backend',

  read(language, namespace, callback) {
    import(`./locales/${language}/${namespace}.json`)
      .then((ret) => {
        callback(null, ret);
      })
      .catch((error) => {
        callback(error, null);
      });
  },
};

const authenticatedUser = {
  name: 'authenticatedUser',

  lookup: function lookup(options) {
    const user = getLoggedUser();
    return user?.locale;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(authenticatedUser);

const supportedLngs = ['dev', 'en', 'en-GB', 'en-US', 'es', 'es-ES', 'de'];

i18n
  .use(Backend)
  .use(languageDetector)
  .init({
    ns: 'base',
    defaultNS: 'base',
    partialBundledLanguages: true,

    fallbackLng: (code) => {
      if (supportedLngs.includes(code)) {
        return ['dev'];
      }
      return ['en'];
    },

    resources: {
      dev: { translation: {} },
    },

    keySeparator: false,
    nsSeparator: false,

    load: 'currentOnly',

    supportedLngs: supportedLngs,

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['authenticatedUser', 'navigator'],
      caches: [],
    },
  });

export default i18n;
