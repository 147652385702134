import React, { useState } from 'react';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { Logo as LogoSVG } from './Logo';

export const Placeholder = ({ alt, sx }) => {
  return (
    <LogoSVG
      title={alt}
      sx={sx}
      fillColor={grey['50']}
      black={grey['50']}
      white={grey['50']}
      red={grey['50']}
      bowColor={grey['200']}
    />
  );
};

export const Image = React.forwardRef(({ src, alt, sx = {} }, ref) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const realImageSx = showPlaceholder ? { display: 'none' } : {};

  const imgProps = {
    component: 'img',
    alt: alt,
    loading: 'lazy',
  };

  const effectiveSx = { border: 1, borderColor: grey[300], ...sx };

  return (
    <>
      {showPlaceholder && <Placeholder imgProps={imgProps} sx={effectiveSx} />}
      {src && (
        <Box
          ref={ref}
          src={src}
          srcSet={`${src} 2x`}
          referrerPolicy="no-referrer"
          onLoad={() => setShowPlaceholder(false)}
          {...imgProps}
          sx={{
            ...effectiveSx,
            ...realImageSx,
          }}
        />
      )}
    </>
  );
});
