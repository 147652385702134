export function AuthError(message) {
  this.message = message;
  this.stack = Error().stack;
}
AuthError.prototype = Object.create(Error.prototype);
AuthError.prototype.name = 'AuthError';

export function HttpResponseError(message, status, response) {
  this.message = message;
  this.status = status;
  this.response = response;
  this.stack = Error().stack;
}
HttpResponseError.prototype = Object.create(Error.prototype);
HttpResponseError.prototype.name = 'HttpResponseError';
