import {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useLayoutEffect,
} from 'react';
import axios from 'axios';
import cookie from 'cookie';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthError } from './errors';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = cookie.parse(document.cookie);
    cookieValue = cookies[name];
  }
  return cookieValue;
}

function clearCookies() {
  const suffix =
    ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC; Path=/; SameSite=Lax';
  document.cookie = suffix;
  document.cookie
    .split('; ')
    .map((x) => x.split('=')[0] + suffix)
    .forEach((x) => (document.cookie = x));
}

const refreshToken = async (token, source) => {
  const data = {
    refresh: token,
  };

  return await axios.request({
    url: '/auth/token/refresh/',
    method: 'POST',
    data: data,
    cancelToken: source?.token,
  });
};

async function replaceFiles(obj) {
  const SEP = '_F_';

  function read(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function () {
        resolve(reader.result);
      };

      reader.onerror = function (e) {
        reject(e);
      };

      reader.readAsBinaryString(file);
    });
  }

  if (Array.isArray(obj)) {
    return Promise.all(obj.map(async (element) => await replaceFiles(element)));
  }

  if (typeof obj === 'object' && obj !== null && !(obj instanceof File)) {
    return Object.fromEntries(
      await Promise.all(
        Object.entries(obj).map(async ([key, value]) => [
          key,
          await replaceFiles(value),
        ])
      )
    );
  }

  if (obj instanceof File) {
    const content = await read(obj);

    return ['', obj.name, btoa(content), ''].join(SEP);
  }

  return obj;
}

export const useRequest = ({
  url,
  initialValue = null,
  authRequired = true,
  method = 'GET',
  initialPayload = null,
  isPaginated = true,
  immediate = true,
  isAuth = false,
}) => {
  const [payload, setPayload] = useState(initialPayload);
  const [targetUrl, setTargetUrl] = useState(url);
  const [response, setResponse] = useState(initialValue);
  const [responseStatus, setResponseStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [nextUrl, setNextUrl] = useState(undefined);

  const [triggered, setTriggered] = useState(immediate);
  const trigger = useCallback(() => {
    setTriggered(true);
  }, []);

  const [initialValueRef] = useState(initialValue);
  const resetResponse = useCallback(() => {
    setTriggered(immediate);
    setResponse((prevResponse) => initialValueRef);
    setResponseStatus(null);
  }, [initialValueRef, immediate]);

  const [authError, setAuthError] = useState(false);

  if (authError) {
    throw new AuthError('AuthError');
  }

  const run = useCallback(
    async (source) => {
      if (authRequired && !auth.token) {
        return;
      }

      if (isAuth && auth.token) {
        return;
      }

      const headers = {
        Accept: 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      };

      if (auth.token) {
        headers['Authorization'] = `Bearer ${auth.token}`;
      }

      setLoading(true);

      const replaced = await replaceFiles(payload);

      try {
        const axiosResponse = await axios.request({
          url: targetUrl,
          method: method,
          headers: headers,
          data: replaced,
          cancelToken: source?.token,
          withCredentials: true,
        });
        setResponseStatus(axiosResponse.status);

        if (isPaginated) {
          setResponse((prevResponse) => [
            ...(prevResponse || []),
            ...axiosResponse.data.results,
          ]);
          setNextUrl(axiosResponse.data.next);
        } else {
          setResponse(axiosResponse.data);
        }
        setError(null);
      } catch (err) {
        setError(err);

        if (axios.isCancel(err)) {
          console.log('Canceled request', targetUrl);
        } else if (err?.response?.status === 401 && !isAuth) {
          if (auth.isRefreshing.current) {
            return;
          }

          auth.isRefreshing.current = true;

          let refreshResponse;
          var success = true;
          try {
            refreshResponse = await refreshToken(auth.refreshToken, source);
          } catch (refreshError) {
            success = false;
            if (axios.isCancel(refreshError)) {
              console.log('Canceled refresh request', targetUrl);
            }

            if (refreshError?.response?.status !== 200) {
              auth.signout();
              setAuthError(true);
            }
          }

          if (success) {
            if (refreshResponse?.status === 200) {
              auth.setToken(refreshResponse.data.access);
            } else {
              console.log('Unexpected refresh response', refreshResponse);
              auth.signout();
            }
          }

          auth.isRefreshing.current = false;
        } else if (
          400 <= err?.response?.status &&
          err?.response?.status < 500
        ) {
          // Client Error
        } else {
          console.log('Throwing', err);
          throw err;
        }
      } finally {
        setLoading(false);
      }
    },
    [auth, targetUrl, method, payload, isPaginated, authRequired, isAuth]
  );

  useLayoutEffect(() => {
    const source = axios.CancelToken.source();

    if (triggered) {
      run(source);
    }

    return () => {
      source.cancel();
    };
  }, [run, triggered]);

  useEffect(() => {
    resetResponse();
    setTargetUrl(url);
  }, [url, resetResponse]);

  const loadNextPage = useCallback(() => {
    if (nextUrl) {
      setTargetUrl(nextUrl);
    } else {
      trigger();
    }
  }, [nextUrl, trigger]);

  return {
    response,
    responseStatus,
    setResponse,
    error,
    loading,
    loadNextPage,
    run,
    trigger,
    payload,
    setPayload,
    resetResponse,
  };
};

const getFromLocalStorage = (key, defaultValue) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    console.log('localStorage parsing error', error);
    return defaultValue;
  }
};

export const getLoggedUser = () => {
  return getFromLocalStorage('user');
};

export function useLocalStorage(key, initialValue) {
  // State to store our value Pass initial state function to useState so
  // logic is only executed once.
  const [storedValue, setStoredValue] = useState(() => {
    return getFromLocalStorage(key, initialValue);
  });

  // https://github.com/uidotdev/usehooks/issues/76#issuecomment-639087236
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.log('localStorage render error', error);
    }
  }, [storedValue, key]);

  return [storedValue, setStoredValue];
}

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const fetchAuthenticatedUserDetails = async (token) => {
  return await axios.request({
    url: '/users/me/',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

function useProvideAuth() {
  const [user, baseSetUser] = useLocalStorage('user', null);
  const [token, setToken] = useLocalStorage('token', null);
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh', null);
  const isRefreshing = useRef(false);
  const { i18n } = useTranslation();

  const setUser = useCallback(
    (user) => {
      if (user) {
        i18n.changeLanguage(user.locale);
      }
      baseSetUser(user);
    },
    [i18n, baseSetUser]
  );

  const signin = useCallback(
    (response, callback) => {
      setToken(response.access);
      setRefreshToken(response.refresh);
      fetchAuthenticatedUserDetails(response.access).then((me) => {
        setUser(me.data);
        callback();
      });
    },
    [setToken, setRefreshToken, setUser]
  );

  const signout = useCallback(() => {
    setUser(null);
    setToken(null);
    setRefreshToken(null);
    clearCookies();
  }, [setToken, setRefreshToken, setUser]);

  const isAuthUserId = useCallback(
    (otherUserId) => {
      return user && user.id === otherUserId;
    },
    [user]
  );

  const isAuthUser = useCallback(
    (otherUser) => {
      return isAuthUserId(otherUser.id);
    },
    [isAuthUserId]
  );

  return {
    user,
    setUser,

    token,
    setToken,

    refreshToken,
    setRefreshToken,

    signin,
    signout,

    isAuthUser,
    isAuthUserId,

    isRefreshing,
  };
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useBackgroundQuery = () => {
  const location = useLocation();
  const search = location?.state?.background.search || '';

  return new URLSearchParams(search);
};

export const useInfiniteScroll = (loadNextPage) => {
  const handleScroll = useCallback(() => {
    const currentPosition =
      window.innerHeight + document.documentElement.scrollTop;

    const milestone = document.documentElement.offsetHeight / 3;

    if (currentPosition >= milestone) {
      loadNextPage();
    }
  }, [loadNextPage]);

  useEffect(() => {
    const reset = () => {
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });

    return reset;
  }, [handleScroll]);
};
