import {
  useState,
  useCallback,
  forwardRef,
  useEffect,
  memo,
  Suspense,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link as RouterLink,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ImageListItem from '@mui/material/ImageListItem';
import Link from '@mui/material/Link';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useUtils as useDateUtils } from '@mui/lab/internal/pickers/hooks/useUtils';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import MobileStepper from '@mui/material/MobileStepper';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PublicIcon from '@mui/icons-material/Public';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import enUsLocale from 'date-fns/locale/en-US';
import enGbLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';
import esLocale from 'date-fns/locale/es';
import SwipeableViews from 'react-swipeable-views';
import { deepPurple } from '@mui/material/colors';
import { styled, alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  createButton,
  createSvgIcon,
  GoogleLoginButton,
  TwitterLoginButton,
} from 'react-social-login-buttons';
import { slugify, getDomain } from './utils/text';
import { filterFalsy } from './utils/objects';
import {
  ProvideAuth,
  useAuth,
  useRequest,
  useInfiniteScroll,
  useQuery,
  useBackgroundQuery,
} from './hooks';
import Form from './Form';
import { Image, Placeholder } from './Image';
import i18n from './i18n';
import ErrorBoundary from './ErrorBoundary';
import Loader from './Loader';
import { Logo as LogoSVG } from './Logo';

const theme = createTheme({
  palette: {
    primary: {
      // main: 'hsl(198,85%,40%)',
      // dark: '#d01716',
      main: deepPurple['800'],
      // light: deepPurple['300'],
      guide: grey['700'],
    },
    // secondary: {
    //   main: '#2196F3',
    // },
  },
  components: {
    MuiStack: {
      variants: [
        {
          props: { variant: 'flexCenter' },
          style: {
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      ],
    },
  },
});

export const Grid = ({ sx, space = 12, gap = 5, ...props }) => (
  <Box
    sx={{
      display: 'grid',
      gap: gap,
      justifyContent: 'space-between',
      gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(
        35
      )}, 1fr))`,
      ...sx,
    }}
  >
    {props.children}
  </Box>
);

const EmailLoginButton = createButton({
  icon: createSvgIcon(EmailIcon),
  iconFormat: (name) => `fa fa-${name}`,
  style: { background: theme.palette.primary.main },
  activeStyle: { background: theme.palette.primary.dark },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BottomNavigationAction = styled(MuiBottomNavigationAction)(
  ({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
      fontSize: theme.typography.pxToRem(12),
    },
    '& .Mui-selected': {
      fontSize: theme.typography.pxToRem(12),
    },
  })
);

const Logo = ({ sx }) => <LogoSVG title="Giftuki" sx={sx} />;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AuthDialog = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    navigate(location.state?.backgroundLocation || '/');
  };

  return (
    <div>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            minHeight: '100%',
            backgroundColor: grey['100'],
          }}
        >
          <IconButton
            onClick={handleClose}
            aria-label={t('Close')}
            sx={{
              position: 'absolute',
              right: 0,
              color: 'primary.main',
              ml: 'auto',
              mt: 5,
              mr: 5,
            }}
          >
            <CloseIcon sx={{ fontSize: (t) => t.spacing(5) }} />
          </IconButton>
          <Container
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
              maxWidth: {
                xs: '100%',
                sm: '75%',
                md: '50%',
                lg: '25%',
              },
            }}
          >
            <Logo sx={{ maxWidth: '100%', mb: 0 }} />
            <Paper
              sx={{
                p: {
                  xs: 2,
                  sm: 5,
                },
              }}
            >
              {children}
            </Paper>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
};

const SocialLogin = ({ emailRoute }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const backgroundLocation = location.state?.backgroundLocation.pathname || '/';
  const socialSuccessPath = `/auth/social-token?next=${backgroundLocation}`;

  const twitterUrl =
    process.env.REACT_APP_API_URL +
    '/auth/login/twitter/?next=' +
    encodeURIComponent(process.env.REACT_APP_UI_URL + socialSuccessPath);
  const googleUrl =
    process.env.REACT_APP_API_URL +
    '/auth/login/google-openidconnect/?next=' +
    encodeURIComponent(process.env.REACT_APP_UI_URL + socialSuccessPath);

  const handleGoogleSignin = async () => {
    window.location.href = googleUrl;
  };

  const handleTwitterSignin = async () => {
    window.location.href = twitterUrl;
  };

  const handleEmailSignin = async () => {
    navigate(emailRoute, { state: location.state });
  };

  return (
    <Box sx={{ my: 2 }}>
      <EmailLoginButton onClick={handleEmailSignin} align="center">
        {t('Email')}
      </EmailLoginButton>
      <GoogleLoginButton onClick={handleGoogleSignin} align="center">
        {t('Google')}
      </GoogleLoginButton>
      <TwitterLoginButton onClick={handleTwitterSignin} align="center">
        {t('Twitter')}
      </TwitterLoginButton>
    </Box>
  );
};

const SignUpDialog = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <AuthDialog>
      <Typography sx={{ m: 1, color: 'primary.guide' }}>
        {t('Sign up with one of the following:')}
      </Typography>

      <SocialLogin emailRoute="/signup/email" />

      <Box sx={{ m: 1, color: 'primary.guide' }}>
        <Typography paragraph sx={{ m: 0 }}>
          {t('Already have an account?')}{' '}
          <Link component={RouterLink} to="/login" state={location.state}>
            {t('Login')}
          </Link>
        </Typography>
        <Typography paragraph sx={{ m: 0 }}>
          {t('Forgot your password?')}{' '}
          <Link
            component={RouterLink}
            to="/reset-password"
            state={location.state}
          >
            {t('Reset password')}
          </Link>
        </Typography>
      </Box>
    </AuthDialog>
  );
};

const ResetPasswordDialog = () => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);

  const request = useRequest({
    url: '/users/reset-password/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: false,
  });

  const fields = [
    {
      id: 'email',
      type: 'email',
      label: t('Email'),
      placeholder: 'email@example.com',
      autoComplete: 'email',
    },
  ];

  useEffect(() => {
    if (request.responseStatus) {
      setSent(true);
    }
  }, [request.responseStatus]);

  return (
    <AuthDialog>
      {!sent && (
        <Form
          fields={fields}
          request={request}
          submitLabel={t('Reset Password')}
        />
      )}

      {sent && (
        <Typography sx={{ p: 2, fontSize: theme.spacing(4) }}>
          {t('Please, check your inbox.')}
        </Typography>
      )}
    </AuthDialog>
  );
};

const LoginDialog = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <AuthDialog>
      <Typography sx={{ m: 1, color: 'primary.guide' }}>
        {t('Log in with one of the following:')}
      </Typography>

      <SocialLogin emailRoute="/login/email" />

      <Box sx={{ m: 1, color: 'primary.guide' }}>
        <Typography paragraph sx={{ m: 0 }}>
          {t("Don't have an account yet?")}{' '}
          <Link component={RouterLink} to="/signup" state={location.state}>
            {t('Sign Up')}
          </Link>
        </Typography>
        <Typography paragraph sx={{ m: 0 }}>
          {t('Forgot your password?')}{' '}
          <Link
            component={RouterLink}
            to="/reset-password"
            state={location.state}
          >
            {t('Reset password')}
          </Link>
        </Typography>
      </Box>
    </AuthDialog>
  );
};

const EmailSignUpDialog = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [sent, setSent] = useState(false);

  const request = useRequest({
    url: '/users/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: false,
  });

  const fields = [
    {
      id: 'username',
      type: 'text',
      label: t('Username'),
      autoComplete: 'username',
      autoFocus: true,
    },
    {
      id: 'password',
      type: 'password',
      label: t('Password'),
      autoComplete: 'new-password',
    },
    {
      id: 'confirm_password',
      type: 'password',
      label: t('Confirm Password'),
      autoComplete: 'new-password',
    },
    {
      id: 'email',
      type: 'email',
      label: t('Email'),
      placeholder: 'email@example.com',
      autoComplete: 'email',
    },
    {
      id: 'birth_date',
      type: 'date',
      label: t('Birthday'),
      // value: '1990-01-01',
      min: '1900-01-01',
      max: '2020-12-31',
    },
    {
      id: 'avatar',
      type: 'file',
      label: t('Avatar'),
    },
  ];

  useEffect(() => {
    if (request.response) {
      setSent(true);
    }
  }, [request.response]);

  return (
    <AuthDialog nestingLevel={2}>
      {!sent && (
        <>
          <Form fields={fields} request={request} submitLabel={t('Sign Up')} />

          <Box sx={{ color: 'primary.guide' }}>
            <Typography paragraph sx={{ m: 0 }}>
              {t('Already have an account?')}{' '}
              <Link component={RouterLink} to="/login" state={location.state}>
                {t('Login')}
              </Link>
            </Typography>
            <Typography paragraph sx={{ m: 0 }}>
              {t('Prefer to use a different method?')}{' '}
              <Link component={RouterLink} to="/signup" state={location.state}>
                {t('Use a social app login')}
              </Link>
            </Typography>
          </Box>
        </>
      )}

      {sent && (
        <Typography sx={{ p: 2, fontSize: theme.spacing(4) }}>
          {t('Please, check your inbox.')}
        </Typography>
      )}
    </AuthDialog>
  );
};

const Activation = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const tokenParam = useQuery().get('token');
  const [uid, token] = tokenParam ? tokenParam.split(':') : [];

  const initialPayload = {
    uid: uid,
    token: token,
  };

  const { responseStatus: activationResponseStatus } = useRequest({
    url: '/users/activation/',
    method: 'POST',
    isPaginated: false,
    immediate: true,
    authRequired: false,
    isAuth: true,
    initialPayload: initialPayload,
  });

  const { response: authResponse, trigger: authTrigger } = useRequest({
    url: '/auth/email-verification-token/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: false,
    isAuth: true,
    initialPayload: initialPayload,
  });

  useEffect(() => {
    if (activationResponseStatus) {
      authTrigger();
    }
  }, [activationResponseStatus, authTrigger]);

  useEffect(() => {
    if (authResponse) {
      signin(authResponse, () => {
        navigate('/');
      });
    }
  }, [signin, navigate, authResponse]);

  return <div />;
};

const EmailLoginDialog = () => {
  const location = useLocation();
  const { signin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backgroundLocation = location.state?.backgroundLocation || '/';

  const request = useRequest({
    url: '/auth/token/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: false,
    isAuth: true,
  });

  const fields = [
    {
      id: 'username',
      type: 'text',
      label: t('Username'),
      autoComplete: 'username',
      autoFocus: true,
    },
    {
      id: 'password',
      type: 'password',
      label: t('Password'),
      autoComplete: 'current-password',
    },
  ];

  useEffect(() => {
    if (request.response) {
      signin(request.response, () => {
        navigate(backgroundLocation);
      });
    }
  }, [signin, navigate, backgroundLocation, request.response]);

  return (
    <AuthDialog nestingLevel={2}>
      <Form fields={fields} request={request} submitLabel={t('Login')} />

      <Box sx={{ color: 'primary.guide' }}>
        <Typography paragraph sx={{ m: 0 }}>
          {t("Don't have an account yet?")}{' '}
          <Link component={RouterLink} to="/signup" state={location.state}>
            {t('Sign Up')}
          </Link>
        </Typography>
        <Typography paragraph sx={{ m: 0 }}>
          {t('Forgot your password?')}{' '}
          <Link
            component={RouterLink}
            to="/reset-password"
            state={location.state}
          >
            {t('Reset password')}
          </Link>
        </Typography>
        <Typography paragraph sx={{ m: 0 }}>
          {t('Prefer to use a different method?')}{' '}
          <Link component={RouterLink} to="/login" state={location.state}>
            {t('Use a social app login')}
          </Link>
        </Typography>
      </Box>
    </AuthDialog>
  );
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const CanonicalGifts = () => {
  const language = navigator.language || navigator.userLanguage || '';
  const endpointQuery = new URLSearchParams(
    filterFalsy({
      language: language,
    })
  );

  const {
    response: gifts,
    loading,
    run,
  } = useRequest({
    url: `/gifts/canonical/?${endpointQuery}`,
    authRequired: false,
    initialValue: [],
  });

  useInfiniteScroll(run);

  return (
    <>
      <GiftList gifts={gifts.map((gift, idx) => ({ ...gift, id: idx }))} />
      {loading && <Loader />}
    </>
  );
};

const UserListPreview = () => {
  const endpointQuery = new URLSearchParams(
    filterFalsy({
      is_staff: 1,
    })
  );

  const { response: users, loading } = useRequest({
    url: `/users/?${endpointQuery}`,
    authRequired: false,
    initialValue: [],
  });

  const maxUsers = 10;

  return (
    <>
      <UserList users={users.slice(0, maxUsers)} />
      {loading && <Loader />}
    </>
  );
};

const HomeFeatures = () => {
  const { t } = useTranslation();

  const sx = {
    my: 5,
    p: 5,
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: (t) => t.shape.borderRadius,
    backgroundImage: (t) =>
      `linear-gradient(45deg, ${t.palette.primary.light}, ${t.palette.primary.dark})`,
    color: 'primary.contrastText',
    alignItems: 'center',
    flexWrap: 'wrap',
  };

  const tsx = {
    fontSize: {
      xs: theme.spacing(3),
      lg: theme.spacing(5),
    },
    textAlign: 'center',
    color: 'primary.contrastText',
    width: {
      xs: '100%',
      lg: '45%',
    },
  };
  const imageSx = {
    mr: 5,
    height: {
      xs: theme.spacing(25),
      lg: theme.spacing(50),
    },
    maxWidth: {
      xs: '100%',
      lg: '50%',
    },
  };

  return (
    <Stack direction="column" sx={{ mb: 3, maxWidth: '100%' }}>
      <Stack direction="row" sx={sx}>
        <Image src="/imgs/wishlist.svg" alt={t('wishlist')} sx={imageSx} />

        <Typography sx={tsx}>
          {t('Organize all your wishlists in one place')}
          {'\u00A0'}
          <span role="img" aria-label={t('Clipboard')}>
            📋
          </span>
        </Typography>
      </Stack>

      <Stack direction="row" sx={sx}>
        <Image src="/imgs/social.svg" alt={t('social')} sx={imageSx} />

        <Typography sx={tsx}>
          {t('Share your Giftuki profile with your contacts')}
          {'\u00A0'}
          <span role="img" aria-label={t('Rocket')}>
            🚀
          </span>
        </Typography>
      </Stack>

      <Stack direction="row" sx={sx}>
        <Image src="/imgs/calendar.svg" alt={t('calendar')} sx={imageSx} />

        <Typography sx={tsx}>
          {t("Don't forget any important dates")}
          {'\u00A0'}
          <span role="img" aria-label={t('Calendar')}>
            📅
          </span>
        </Typography>
      </Stack>

      <Stack direction="row" sx={sx}>
        <Image src="/imgs/gift.svg" alt={t('gift')} sx={imageSx} />

        <Typography sx={tsx}>
          {t('Find the perfect gift idea')}
          {'\u00A0'}
          <span role="img" aria-label={t('Wrapped present')}>
            🎁
          </span>
        </Typography>
      </Stack>
    </Stack>
  );
};

const HomeCallToAction = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: 7,
        mb: 0,
        backgroundColor: 'transparent',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: 3,
          color: 'primary.contrastText',
          textShadow: (t) => `0 0 10px ${t.palette.primary.main}`,
          textAlign: 'center',
          fontSize: {
            xs: theme.spacing(5),
            lg: theme.spacing(8),
          },
        }}
      >
        {t('Gifting: No longer a pain in the ass')}
      </Typography>
      {!auth.token && (
        <Box sx={{ textAlign: 'center' }}>
          <Button
            component={RouterLink}
            to="/signup/"
            variant="contained"
            size="large"
            sx={{ py: 2, px: 4, fontSize: theme.spacing(2) }}
          >
            {t('Get Started')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const HomeBackground = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: (t) => t.spacing(15),
        left: 0,
        zIndex: -1,
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          backgroundImage: (t) =>
            `radial-gradient(${t.palette.primary.dark}, ` +
            `${t.palette.primary.light}), url(imgs/hero.jpg)`,
          backgroundBlendMode: 'multiply',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          transform: 'skewY(-2.5deg)',
          transformOrigin: 'right top',
          height: (t) => t.spacing(70),
        }}
      />
    </Box>
  );
};

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HomeBackground />
      <HomeCallToAction />
      <HomeFeatures />
      <Typography variant="h2" sx={{ pb: 3 }}>
        {t('Find your friends')}
      </Typography>
      <UserListPreview />
      <Typography variant="h2" sx={{ pt: 5, pb: 3 }}>
        {t('Browse some random gift ideas')}
      </Typography>
      <CanonicalGifts />
    </>
  );
};

const gravatar = (
  email_md5,
  size = '50',
  rating = 'g',
  _default = 'robohash'
) => {
  const base = `//www.gravatar.com/avatar/`;

  const query = new URLSearchParams({
    s: size,
    r: rating,
    d: _default,
  });

  return `${base}${email_md5}?${query}`;
};

const UserListItemBase = ({ user }) => {
  const { t } = useTranslation();
  const dateUtils = useDateUtils();

  const getBirthDateString = (birth_date) => {
    const _date = new Date(birth_date);
    if (!_date) {
      return '---';
    }
    return `${formatDate(_date, dateUtils)}\n${remaining(_date, t)}`;
  };

  return (
    <ImageListItem>
      <Link
        component={RouterLink}
        to={`/users/${user.id}/${slugify(user.username)}/`}
      >
        <Image
          src={user.avatar || gravatar(user.email_md5, theme.spacing(35))}
          alt={user.username}
          sx={{
            width: '100%',
            height: (t) => t.spacing(35),
            objectFit: 'cover',
          }}
        />
        <ImageListItemBar
          position="bottom"
          title={user.username}
          subtitle={getBirthDateString(user.birth_date)}
          sx={{
            '& > div > div': { whiteSpace: 'pre-line' },
          }}
        />
      </Link>
    </ImageListItem>
  );
};

const UserListItem = memo(
  UserListItemBase,
  (prevProps, nextProps) => prevProps.user.id === nextProps.user.id
);

const UserList = ({ users }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(12rem, 100%), 1fr))',
        gap: 2,
      }}
    >
      {users.map((user) => {
        return <UserListItem key={user.id} user={user} />;
      })}
    </Box>
  );
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(grey[500], 0.15),
  '&:hover': {
    backgroundColor: alpha(grey[500], 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const SearchForm = ({ querySearch, sx }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(querySearch || '');

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const doSearch = () => {
    const uiQuery = new URLSearchParams(
      filterFalsy({
        search: searchInput,
      })
    );
    navigate({ search: uiQuery.toString() });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
        document.activeElement.blur();
        doSearch(searchInput);
      }}
      sx={sx}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': t('search') }}
          value={searchInput}
          onChange={handleSearchChange}
        />
      </Search>
    </Box>
  );
};

const UsersPage = () => {
  const querySearch = useQuery().get('search');
  const backgroundSearch = useBackgroundQuery().get('search');
  const effectiveSearch = querySearch || backgroundSearch;
  const auth = useAuth();

  const endpointQuery = new URLSearchParams(
    filterFalsy({
      search: querySearch,
    })
  );

  const {
    response: users,
    loading,
    loadNextPage,
  } = useRequest({
    url:
      querySearch || !auth.user
        ? `/users/?${endpointQuery}`
        : `/users/${auth.user.id}/connections/`,
    authRequired: false,
    initialValue: [],
  });

  useInfiniteScroll(loadNextPage);

  const length = users.length;

  return (
    <>
      <SearchForm querySearch={effectiveSearch} sx={{ mb: 5 }} />

      {!loading &&
        ((length === 0 && !querySearch && <EmptyUserConnections />) ||
          (length === 0 && querySearch && <EmptyUserSearch />) || (
            <UserList users={users} />
          ))}
      {loading && <Loader />}
    </>
  );
};

function remaining(date, t) {
  const now = new Date();
  const _date = new Date(date.getTime());

  _date.setYear(now.getUTCFullYear());

  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  _date.setHours(0);
  _date.setMinutes(0);
  _date.setSeconds(0);
  _date.setMilliseconds(0);

  const diff = new Date(_date.getTime() - now.getTime());

  const months = diff.getUTCMonth();
  const days = diff.getUTCDate() - 1;

  let ret = '';

  if (months > 0) {
    ret = t('{{count}} month', {
      count: months,
      defaultValue_plural: '{{count}} months',
    });
  }

  if (days > 0) {
    if (ret) {
      ret += t(' ');
    }

    ret += t('{{count}} day', {
      count: days,
      defaultValue_plural: '{{count}} days',
    });
  }

  if (!ret) {
    ret = t('Today!');
  } else {
    ret = t('{{remaining}} left', { remaining: ret });
  }

  return ret;
}

const EditProfile = ({ sx }) => {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const request = useRequest({
    url: `/users/me/`,
    method: 'PATCH',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const fields = [
    {
      id: 'username',
      type: 'text',
      label: t('Username'),
      value: user.username,
      autoFocus: true,
    },
    {
      id: 'password',
      type: 'password',
      label: t('Password'),
    },
    {
      id: 'confirm_password',
      type: 'password',
      label: t('Confirm Password'),
    },
    {
      id: 'email',
      type: 'email',
      label: t('Email'),
      value: user.email,
    },
    {
      id: 'birth_date',
      type: 'date',
      label: t('Birthday'),
      value: user.birth_date,
      min: '1900-01-01',
      max: '2020-12-31',
    },
    {
      id: 'avatar',
      type: 'file',
      label: t('Avatar'),
      value: user.avatar,
    },
    {
      id: 'locale',
      type: 'select',
      label: t('Language'),
      value: user.locale,
      options: [
        { value: 'en-US', label: t('American English') },
        { value: 'en-GB', label: t('British English') },
        { value: 'en', label: t('English') },
        { value: 'es', label: t('Spanish') },
        { value: 'de', label: t('German') },
      ].sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? +1 : 0)),
    },
  ];

  useEffect(() => {
    if (request.response) {
      setUser(request.response);
      close();
    }
  }, [request.response, setUser, close]);

  return (
    <>
      <Button variant="contained" endIcon={<EditIcon />} onClick={open} sx={sx}>
        {t('Edit Profile')}
      </Button>

      <ClosableDialog open={isOpen} close={close}>
        <Paper sx={{ p: 5 }}>
          <DialogTitle>{t('Edit Profile')}</DialogTitle>
          <Form fields={fields} request={request} submitLabel={t('Save')} />
        </Paper>
      </ClosableDialog>
    </>
  );
};

const InvisibleButton = ({ sx, ...props }) => {
  return <Button sx={{ ...sx, opacity: 0, cursor: 'default' }}>.</Button>;
};

const UserConnectionButton = ({ user, sx }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [connected, setConnected] = useState(user.connected);

  const CONNECTED = 'CONNECTED';
  const DISCONNECTED = 'DISCONNECTED';
  const PENDING = 'PENDING';
  const ACCEPTABLE = 'ACCEPTABLE';

  const TITLES = {
    CONNECTED: t('Disconnection confirmation'),
    DISCONNECTED: t('Connection confirmation'),
    PENDING: t('Cancel connection request'),
    ACCEPTABLE: t('Accept connection request'),
  };

  const messages = (connected, name) =>
    ({
      CONNECTED: t(
        'Are you sure you want to delete your connection to {{name}}?',
        { name: name }
      ),
      DISCONNECTED: t('Are you sure you want to connect to {{name}}?', {
        name: name,
      }),
      PENDING: t(
        'Are you sure you want to cancel your connection request to {{name}}?',
        { name: name }
      ),
      ACCEPTABLE: t(
        'Are you sure you want to accept the connection request from {{name}}?',
        { name: name }
      ),
    }[connected]);

  const BUTTONS = {
    CONNECTED: t('Disconnect'),
    DISCONNECTED: t('Connect'),
    PENDING: t('Cancel request'),
    ACCEPTABLE: t('Accept request'),
  };

  const leftId = auth.user?.id;
  const rightId = user.id;

  const {
    trigger: connectTrigger,
    response: connectResponse,
    resetResponse: connectResetResponse,
  } = useRequest({
    url: `/users/${leftId}/connections/`,
    method: 'POST',
    initialPayload: { right: rightId },
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const {
    trigger: disconnectTrigger,
    responseStatus: disconnectResponseStatus,
    resetResponse: disconnectResetResponse,
  } = useRequest({
    url: `/users/${leftId}/connections/${rightId}/`,
    method: 'DELETE',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const {
    trigger: acceptTrigger,
    responseStatus: acceptResponseStatus,
    resetResponse: acceptResetResponse,
  } = useRequest({
    url: `/users/${leftId}/connections/${rightId}/`,
    method: 'PUT',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const performConnect = () => {
    connectTrigger();
  };
  const performCancel = () => {
    disconnectTrigger();
  };
  const performAccept = () => {
    acceptTrigger();
  };

  const { isOpen, open, close } = useDialogState();

  useEffect(() => {
    if (connectResponse) {
      close();
      connectResetResponse();
      setConnected(PENDING);
    }
  }, [connectResponse, connectResetResponse, close]);

  useEffect(() => {
    if (disconnectResponseStatus) {
      close();
      disconnectResetResponse();
      setConnected(DISCONNECTED);
    }
  }, [disconnectResponseStatus, disconnectResetResponse, close]);

  useEffect(() => {
    if (acceptResponseStatus) {
      close();
      acceptResetResponse();
      setConnected(CONNECTED);
    }
  }, [acceptResponseStatus, acceptResetResponse, close]);

  const ACTIONS = {
    CONNECTED: performCancel,
    DISCONNECTED: performConnect,
    PENDING: performCancel,
    ACCEPTABLE: performAccept,
  };

  const [hover, setHover] = useState(false);

  function onMouseEnter(event) {
    setHover(true);
  }

  function onMouseLeave(event) {
    setHover(false);
  }

  return (
    <>
      {(!auth.user || auth.isAuthUser(user)) && <InvisibleButton sx={sx} />}
      {connected === DISCONNECTED && auth.user && !auth.isAuthUser(user) && (
        <Button
          onClick={open}
          variant="contained"
          sx={sx}
          endIcon={<PersonAddIcon />}
        >
          {t('Connect')}
        </Button>
      )}
      {connected === CONNECTED && (
        <Button
          variant="contained"
          onClick={open}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          sx={{
            ...sx,
            backgroundColor: 'primary.main',
            ':hover': { backgroundColor: 'error.dark' },
          }}
          endIcon={hover ? <PersonRemoveIcon /> : <PeopleIcon />}
        >
          {hover ? t('Disconnect') : t('Connected')}
        </Button>
      )}
      {connected === ACCEPTABLE && (
        <Button
          onClick={open}
          variant="contained"
          color="success"
          sx={sx}
          endIcon={<PersonAddIcon />}
        >
          {t('Accept request')}
        </Button>
      )}
      {connected === PENDING && (
        <Button
          onClick={open}
          variant="outlined"
          sx={sx}
          endIcon={<DoDisturbIcon />}
        >
          {t('Cancel request')}
        </Button>
      )}

      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby="confirm-user-connection-action-title"
      >
        <DialogTitle id="confirm-user-connection-action-title">
          {TITLES[connected]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {messages(connected, user.username)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            {t('Close')}
          </Button>
          <Button onClick={ACTIONS[connected]}>{BUTTONS[connected]}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UserAvatar = ({ user, size, sx }) => {
  const { t } = useTranslation();

  return (
    <Avatar
      alt={t('Avatar of {{username}}', { username: user.username })}
      src={user.avatar || gravatar(user.email_md5, theme.spacing(35))}
      sx={{
        width: theme.spacing(size),
        height: theme.spacing(size),
        backgroundColor: 'primary.contrastText',
        ...sx,
      }}
    >
      {user.username}
    </Avatar>
  );
};

const formatDate = (date, dateUtils) => {
  return dateUtils.format(date, 'normalDate');
};

const UserDetail = ({ user, sx }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const dateUtils = useDateUtils();

  const _date = new Date(user.birth_date);

  const buttonSx = {
    ml: 2,
    verticalAlign: 'text-bottom',
  };

  return (
    <Box sx={sx}>
      <Container
        sx={{
          mb: 2,
          width: '100%',
          backgroundColor: 'primary.light',
        }}
      >
        <UserAvatar user={user} size={30} sx={{ mx: 'auto' }} />
      </Container>

      <Box>
        <Typography variant="h2" component="span">
          {user.username}
        </Typography>

        {auth.isAuthUser(user) && <EditProfile sx={buttonSx} />}

        {!auth.isAuthUser(user) && (
          <UserConnectionButton user={user} sx={buttonSx} />
        )}
      </Box>

      <Typography variant="h3">{formatDate(_date, dateUtils)}</Typography>
      <Typography variant="h4">{remaining(_date, t)}</Typography>
    </Box>
  );
};

const GiftCardLink = ({ link }) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: (t) => `solid 1px ${t.palette.primary.main}`,
        borderRadius: 9999,
        p: 1,
        color: 'primary.main',
        ':hover': {
          color: 'primary.contrastText',
          backgroundColor: 'primary.light',
        },
      }}
    >
      <Link
        href={link.url}
        rel="noopener noreferrer nofollow"
        target="_blank"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',

            // truncated
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
          }}
        >
          {getDomain(link.url)}
        </Typography>
      </Link>
    </Box>
  );
};

const useDialogState = (initial = false) => {
  const [isOpen, setIsOpen] = useState(initial);
  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, open, close };
};

const FormDialog = ({
  request,
  fields,
  title,
  isOpen,
  close,
  value,
  submitLabel,
  children,
  ...rest
}) => {
  return (
    <ClosableDialog open={isOpen} close={close}>
      <Paper sx={{ p: 5 }}>
        <DialogTitle>{title}</DialogTitle>
        <Form
          fields={fields}
          request={request}
          value={value}
          submitLabel={submitLabel}
          {...rest}
        >
          {children}
        </Form>
      </Paper>
    </ClosableDialog>
  );
};

const Input = styled('input')({
  display: 'none',
});

const ImageControls = ({
  gift,
  setGift,
  imageIdx,
  resetImageIdx,
  handleBack,
}) => {
  const { t } = useTranslation();
  const imageId = gift.images[imageIdx]?.id;

  const {
    trigger: deleteTrigger,
    responseStatus: deleteResponseStatus,
    resetResponse: deleteResetResponse,
    loading: deleteLoading,
  } = useRequest({
    url: `/gifts/${gift.id}/images/${imageId}/`,
    method: 'DELETE',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const deleteImage = () => {
    deleteTrigger();
  };

  useEffect(() => {
    if (deleteResponseStatus) {
      deleteResetResponse();

      if (imageIdx >= gift.images.length - 1 && imageIdx >= 1) {
        handleBack();
      }

      setGift((prevGift) => {
        return {
          ...prevGift,
          images: prevGift.images.filter((image) => image.id !== imageId),
        };
      });
    }
  }, [
    deleteResponseStatus,
    deleteResetResponse,
    setGift,
    imageId,
    imageIdx,
    gift.images.length,
    handleBack,
  ]);

  const {
    setPayload,
    trigger,
    response,
    loading: uploadLoading,
  } = useRequest({
    url: `/gifts/${gift.id}/images/`,
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const onChange = (event) => {
    setPayload({ content: event.target.files[0] });
    trigger();
  };

  useEffect(() => {
    if (response) {
      resetImageIdx();
      setGift(response);
    }
  }, [response, setGift, resetImageIdx]);

  const loading = deleteLoading || uploadLoading;

  return (
    <Stack direction="row" spacing={5} justifyContent="center" sx={{ my: 3 }}>
      {(loading && <Loader size={30} />) || (
        <>
          <Box component="form">
            <label htmlFor="upload-image">
              <Input
                accept="image/*"
                id="upload-image"
                type="file"
                onChange={onChange}
              />
              <IconButton
                component="span"
                aria-label={t('upload new image')}
                color="primary"
                sx={{ p: 0 }}
              >
                <AddPhotoAlternateIcon
                  sx={{ fontSize: theme.typography.pxToRem(40) }}
                />
              </IconButton>
            </label>
          </Box>

          {imageId && (
            <IconButton
              onClick={deleteImage}
              aria-label={t('delete image')}
              color="primary"
              sx={{ p: 0 }}
            >
              <DeleteIcon sx={{ fontSize: theme.typography.pxToRem(40) }} />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};

const GalleryDialog = ({ isOpen, close, gift, setGift }) => {
  const alt = gift.name;
  const { t } = useTranslation();
  const auth = useAuth();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const resetImageIdx = useCallback(() => {
    setActiveStep(0);
  }, []);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const imgProps = {
    component: 'img',
    alt: alt,
    loading: 'lazy',
    sx: {
      mx: 'auto',
      overflow: 'hidden',
      display: 'block',
      width: '100%',
      maxWidth: 400,
    },
  };

  return (
    <ClosableDialog open={isOpen} close={close}>
      <Paper sx={{ p: 5 }}>
        <DialogTitle>{alt}</DialogTitle>

        {(gift.images.length === 0 && (
          <EmptyImageList ownerId={gift.owner} />
        )) || (
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {gift.images.map((image, index) => (
              <div key={image.id} sx={{ display: 'flex' }}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box src={image.content} {...imgProps} />
                ) : null}
              </div>
            ))}
          </SwipeableViews>
        )}

        {auth.isAuthUserId(gift.owner) && (
          <ImageControls
            gift={gift}
            setGift={setGift}
            imageIdx={activeStep}
            resetImageIdx={resetImageIdx}
            handleBack={handleBack}
          />
        )}

        {gift.images.length !== 0 && (
          <MobileStepper
            steps={gift.images.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === gift.images.length - 1}
              >
                {t('Next')}
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                {t('Back')}
              </Button>
            }
          />
        )}
      </Paper>
    </ClosableDialog>
  );
};

const DeleteGift = ({ gift, setWishlist, isOpen, close }) => {
  const { t } = useTranslation();

  const request = useRequest({
    url: `/gifts/${gift.id}/`,
    method: 'DELETE',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const performDelete = () => {
    request.trigger();
  };

  useEffect(() => {
    if (request.responseStatus) {
      setWishlist((prevWishlist) => {
        const gifts = prevWishlist.gifts.filter((x) => x.id !== gift.id);
        return { ...prevWishlist, gifts: gifts };
      });
      close();
    }
  }, [request.responseStatus, setWishlist, gift.id, close]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="confirm-deletion-title"
    >
      <DialogTitle id="confirm-deletion-title">
        {t('Deletion confirmation')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Are you sure you want to delete this item? This action can't be undone."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={close}>
          {t('Cancel')}
        </Button>
        <Button onClick={performDelete}>{t('Delete')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const MoveGift = ({ gift, wishlist, setWishlist, isOpen, close }) => {
  const { t } = useTranslation();

  const endpointQuery = new URLSearchParams({
    owner: gift.owner,
  });

  const { response: wishlists } = useRequest({
    url: `/wishlists/?${endpointQuery}`,
    authRequired: false,
    initialValue: [],
  });

  const fields = [
    {
      id: 'destination',
      type: 'select',
      label: t('Destination'),
      options: wishlists
        .filter((_wishlist) => _wishlist.id !== wishlist.id)
        .map((wishlist) => ({
          value: wishlist.id,
          label: wishlist.name,
        })),
    },
  ];

  const [destination, setDestination] = useState(null);

  const {
    setPayload: addGiftSetPayload,
    trigger: addGiftTrigger,
    response: addGiftResponse,
  } = useRequest({
    url: `/wishlists/${destination}/gifts/`,
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const { trigger: deleteGiftTrigger, responseStatus: deleteResponseStatus } =
    useRequest({
      url: `/wishlists/${wishlist.id}/gifts/${gift.id}/`,
      method: 'DELETE',
      isPaginated: false,
      immediate: false,
      authRequired: true,
    });

  const onSubmit = (value) => {
    setDestination(value.destination);
  };

  useEffect(() => {
    if (destination) {
      addGiftSetPayload({ id: gift.id });
      addGiftTrigger();
    }
  }, [destination, addGiftSetPayload, addGiftTrigger, gift.id]);

  useEffect(() => {
    if (addGiftResponse) {
      deleteGiftTrigger();
    }
  }, [addGiftResponse, deleteGiftTrigger]);

  useEffect(() => {
    if (deleteResponseStatus) {
      setWishlist((prevWishlist) => {
        const gifts = prevWishlist.gifts.filter((x) => x.id !== gift.id);
        return { ...prevWishlist, gifts: gifts };
      });
      close();
    }
  }, [setWishlist, deleteResponseStatus, gift.id, close]);

  return (
    <FormDialog
      title={t('Move Gift')}
      fields={fields}
      isOpen={isOpen}
      close={close}
      submitLabel={t('Move')}
      onSubmit={onSubmit}
    />
  );
};

const UpdateGift = ({ gift, setGift, isOpen, close }) => {
  const { t } = useTranslation();

  const request = useRequest({
    url: `/gifts/${gift.id}/`,
    method: 'PATCH',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const fields = [
    {
      id: 'name',
      type: 'text',
      label: t('name'),
      autoFocus: true,
    },
    {
      id: 'price',
      type: 'text',
      label: t('price'),
      verbose: true,
      gridItemProps: { xs: 6 },
    },
    {
      id: 'price_currency',
      type: 'select',
      label: t('currency'),
      verbose: true,
      gridItemProps: { xs: 6 },
      options: [
        { value: 'USD', label: t('Dollar $') },
        { value: 'EUR', label: t('Euro €') },
        { value: 'GBP', label: t('Pound £') },
      ],
    },
    {
      id: 'image',
      type: 'image',
      label: t('image extracted from your url'),
      verbose: true,
    },
    {
      id: 'links',
      label: t('Links'),
      type: 'array',
      items: [
        {
          id: 'url',
          type: 'url',
          label: t('url'),
          onChange: onChangeUrl,
        },
      ],
    },
    {
      id: 'notes',
      type: 'text',
      label: t('notes'),
      multiline: true,
      minRows: 2,
      maxRows: 10,
    },
  ];

  useEffect(() => {
    if (request.response) {
      // Add preview image if we have loaded one during the form
      // preparation.
      const newGift = request.response;

      newGift.images.push({
        id: newGift.images.length + 1,
        content: request.payload.image,
      });

      newGift.previews.push({
        id: newGift.previews.length + 1,
        content: request.payload.image,
      });

      setGift(newGift);
      close();
    }
  }, [request.response, request.payload?.image, setGift, close]);

  return (
    <FormDialog
      title={t('Edit Gift')}
      fields={fields}
      request={request}
      isOpen={isOpen}
      close={close}
      value={gift}
      submitLabel={t('Save')}
    />
  );
};

const PromiseDialog = ({ gift, setGift, isOpen, close }) => {
  const { t } = useTranslation();

  const creationRequest = useRequest({
    url: '/promises/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const updateRequest = useRequest({
    url: `/promises/${gift.promise?.id}/`,
    method: 'PATCH',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  useEffect(() => {
    if (creationRequest.response) {
      setGift((gift) => {
        return {
          ...gift,
          promise: creationRequest.response,
        };
      });
      close();
    }
  }, [creationRequest.response, setGift, close]);

  useEffect(() => {
    if (updateRequest.response) {
      setGift((gift) => {
        return {
          ...gift,
          promise: updateRequest.response,
        };
      });
      close();
    }
  }, [updateRequest.response, setGift, close]);

  const fields = [
    {
      id: 'target_date',
      type: 'date',
      label: t('Target date'),
      value: new Date(),
    },
    {
      id: 'purchased',
      type: 'checkbox',
      label: t('Purchased'),
    },
    {
      id: 'delivered',
      type: 'checkbox',
      label: t('Delivered'),
    },
  ];

  const deleteRequest = useRequest({
    url: `/promises/${gift.promise?.id}/`,
    method: 'DELETE',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const handleDelete = () => {
    deleteRequest.trigger();
  };

  useEffect(() => {
    if (deleteRequest.responseStatus) {
      setGift((gift) => {
        return {
          ...gift,
          promise: false,
        };
      });
      close();
    }
  }, [deleteRequest.responseStatus, setGift, close]);

  return (
    <>
      {(gift.promise === false && (
        <FormDialog
          title={t('Book it')}
          fields={fields}
          request={creationRequest}
          isOpen={isOpen}
          close={close}
          submitLabel={t('Save')}
          extraPayload={{ gift: gift.id }}
        />
      )) || (
        <FormDialog
          title={t('Edit booking')}
          fields={fields}
          request={updateRequest}
          isOpen={isOpen}
          close={close}
          value={gift.promise}
          submitLabel={t('Save')}
          extraPayload={{ gift: gift.id }}
        >
          <Button
            onClick={handleDelete}
            variant="outlined"
            sx={{ width: '100%' }}
          >
            {t('Cancel Booking')}
          </Button>
        </FormDialog>
      )}
    </>
  );
};

const GiftCardButtons = ({ gift, setGift }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const {
    isOpen: isOpenPromiseDialog,
    open: openPromiseDialog,
    close: closePromiseDialog,
  } = useDialogState();

  return (
    <>
      {!auth.isAuthUserId(gift.owner) &&
        auth.user &&
        gift.owner &&
        !gift.archived &&
        (!gift.promise || gift.promise.id) && (
          <IconButton onClick={openPromiseDialog} aria-label={t('book it')}>
            <CardGiftcardIcon />
          </IconButton>
        )}

      {gift.notes && (
        <Tooltip title={gift.notes} enterTouchDelay={0} leaveTouchDelay={2000}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}

      <PromiseDialog
        gift={gift}
        setGift={setGift}
        isOpen={isOpenPromiseDialog}
        close={closePromiseDialog}
      />
    </>
  );
};

const GiftCardMenu = ({ gift, wishlist, setGift, setWishlist }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    isOpen: isOpenMoveDialog,
    open: openMoveDialog,
    close: closeMoveDialog,
  } = useDialogState();

  const {
    isOpen: isOpenUpdateDialog,
    open: openUpdateDialog,
    close: closeUpdateDialog,
  } = useDialogState();

  const {
    isOpen: isOpenDeleteDialog,
    open: openDeleteDialog,
    close: closeDeleteDialog,
  } = useDialogState();

  const handleUpdateClick = () => {
    handleClose();
    openUpdateDialog();
  };

  const handleMoveClick = () => {
    handleClose();
    openMoveDialog();
  };

  const handleDeleteClick = () => {
    handleClose();
    openDeleteDialog();
  };

  const request = useRequest({
    url: `/gifts/${gift.id}/`,
    method: 'PATCH',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const handleArchiveClick = () => {
    handleClose();

    const payload = {
      archived: !gift.archived,
    };

    request.setPayload(payload);
    request.trigger();
  };

  useEffect(() => {
    if (request.response) {
      setGift(request.response);
    }
  }, [request.response, setGift]);

  return (
    <>
      <IconButton
        id="gift-card-options"
        aria-controls="gift-options"
        aria-label={t('gift options')}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="gift-options"
        MenuListProps={{
          'aria-labelledby': 'gift-card-options',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleUpdateClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Update')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMoveClick}>
          <ListItemIcon>
            <DriveFileMoveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Move')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleArchiveClick}>
          <ListItemIcon>
            {(gift.archived && <UnarchiveIcon fontSize="small" />) || (
              <ArchiveIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>
            {(gift.archived && t('Unarchive')) || t('Archive')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>

      <UpdateGift
        gift={gift}
        setGift={setGift}
        isOpen={isOpenUpdateDialog}
        close={closeUpdateDialog}
      />
      {isOpenMoveDialog && (
        <MoveGift
          gift={gift}
          wishlist={wishlist}
          setWishlist={setWishlist}
          isOpen={isOpenMoveDialog}
          close={closeMoveDialog}
        />
      )}
      <DeleteGift
        gift={gift}
        setWishlist={setWishlist}
        isOpen={isOpenDeleteDialog}
        close={closeDeleteDialog}
      />
    </>
  );
};

const GiftListItemBase = ({ gift, wishlist, giftIdx, setWishlist }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isOpen,
    open: stateOpen,
    close: stateClose,
  } = useDialogState(params.giftId === gift.id.toString());

  const setGift = useCallback(
    (gift) => {
      setWishlist((prevWishlist) => {
        const prevGift = { ...prevWishlist.gifts[giftIdx] };
        const effectiveGift =
          typeof gift === 'function' ? gift(prevGift) : gift;

        const newWishlist = {
          ...prevWishlist,
          gifts: [...prevWishlist.gifts],
        };
        newWishlist.gifts[giftIdx] = effectiveGift;
        return newWishlist;
      });
    },
    [setWishlist, giftIdx]
  );

  const open = () => {
    if (gift.owner === null) {
      return;
    }
    const slash = location.pathname.endsWith('/') ? '' : '/';
    navigate(`${location.pathname}${slash}${gift.id}/${slugify(gift.name)}`);
    stateOpen();
  };

  const close = () => {
    navigate(location.pathname.split('/').slice(0, -2).join('/'));
    stateClose();
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        display: 'flex',
        flexDirection: 'column',
        filter: gift.archived ? 'contrast(100%) brightness(50%)' : undefined,
        backgroundColor: gift.promise?.id ? 'success.light' : undefined,
      }}
    >
      <CardActionArea onClick={open}>
        <CardHeader
          title={gift.name || ''}
          sx={{
            '> div': {
              width: '100%',
            },
          }}
          titleTypographyProps={{
            title: gift.name,
            sx: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
        <CardMedia
          component={() => (
            <Container>
              <PreviewGallery images={gift.images} alt={gift.name} />
            </Container>
          )}
        />
      </CardActionArea>

      <GalleryDialog
        isOpen={isOpen}
        close={close}
        gift={gift}
        setGift={setGift}
      />

      <CardContent>
        {gift.links.length > 0 && (
          <Typography sx={{ textAlign: 'center' }}>
            {t('Go to store', {
              count: gift.links.length,
              defaultValue_plural: 'Go to stores',
            })}
          </Typography>
        )}

        {gift.links.map((link) => (
          <GiftCardLink key={link.id} link={link} />
        ))}
      </CardContent>

      <Box sx={{ my: 'auto' }} />

      <CardActions disableSpacing>
        <GiftCardButtons gift={gift} setGift={setGift} />

        <Box sx={{ mx: 'auto' }} />

        {auth.isAuthUserId(gift.owner) && (
          <GiftCardMenu
            gift={gift}
            wishlist={wishlist}
            setGift={setGift}
            setWishlist={setWishlist}
          />
        )}
      </CardActions>
    </Card>
  );
};

const GiftListItem = memo(
  GiftListItemBase,
  (prevProps, nextProps) =>
    prevProps.gift.id === nextProps.gift.id &&
    prevProps.gift.name === nextProps.gift.name &&
    prevProps.gift.links === nextProps.gift.links &&
    prevProps.gift.images === nextProps.gift.images &&
    prevProps.gift.promise === nextProps.gift.promise
);

const GiftList = ({ gifts, wishlist, setWishlist }) => {
  return (
    <Grid>
      {gifts.map((gift, idx) => {
        return (
          <GiftListItem
            key={gift.id}
            gift={gift}
            giftIdx={idx}
            wishlist={wishlist}
            setWishlist={setWishlist}
          />
        );
      })}
    </Grid>
  );
};

const Visibility = ({ visibility, ...props }) => {
  return (
    <>
      {visibility === 'public' && (
        <PublicIcon title="public visibility" {...props} />
      )}
      {visibility === 'private' && (
        <LockOpenIcon title="private visibility" {...props} />
      )}
    </>
  );
};

const WishlistDetail = ({ wishlist, setWishlist, owner }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction="row"
        sx={{
          mb: 5,
          flexWrap: {
            xs: 'wrap',
            sm: 'nowrap',
          },
        }}
      >
        <Link
          component={RouterLink}
          to={`/users/${owner.id}/${slugify(owner.username)}/`}
        >
          <UserAvatar user={owner} size={20} sx={{ mr: 5 }} />
        </Link>

        <Stack direction="column">
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: theme.typography.pxToRem(30),
                sm: theme.typography.pxToRem(40),
                md: theme.typography.pxToRem(50),
                lg: theme.typography.pxToRem(60),
              },
            }}
          >
            {wishlist.name}
          </Typography>
          <Typography>
            {t('{{count}} item', {
              count: wishlist.gifts.length,
              defaultValue_plural: '{{count}} items',
            })}
          </Typography>
          <Visibility
            visibility={wishlist.visibility}
            sx={{ fontSize: theme.typography.pxToRem(40) }}
          />
        </Stack>
      </Stack>

      <NewGift wishlist={wishlist} setWishlist={setWishlist} />

      {wishlist.gifts.length === 0 && auth.isAuthUserId(wishlist.owner) && (
        <EmptyOwnedWishlist />
      )}
      {wishlist.gifts.length === 0 && !auth.isAuthUserId(wishlist.owner) && (
        <EmptyNotOwnedWishlist />
      )}
      {wishlist.gifts.length !== 0 && (
        <GiftList
          gifts={wishlist.gifts}
          wishlist={wishlist}
          setWishlist={setWishlist}
        />
      )}
    </>
  );
};

const WishlistPage = () => {
  const params = useParams();

  const {
    response: wishlist,
    setResponse: setWishlist,
    loading: wishlistLoading,
  } = useRequest({
    url: `/wishlists/${params.id}/`,
    authRequired: false,
    isPaginated: false,
  });

  const {
    response: owner,
    trigger: ownerTrigger,
    loading: ownerLoading,
  } = useRequest({
    url: `/users/${wishlist?.owner}/`,
    authRequired: false,
    isPaginated: false,
    immediate: false,
  });

  useEffect(() => {
    if (wishlist) {
      ownerTrigger();
    }
  }, [wishlist, ownerTrigger]);

  const loading = wishlistLoading || ownerLoading;

  return (
    <>
      {!loading && wishlist?.id && owner?.id && (
        <WishlistDetail
          wishlist={wishlist}
          setWishlist={setWishlist}
          owner={owner}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

const UserPage = () => {
  const params = useParams();

  const { response: user, loading: userLoading } = useRequest({
    url: `/users/${params.id}/`,
    authRequired: false,
    isPaginated: false,
  });

  const endpointQuery = new URLSearchParams(
    filterFalsy({
      owner: params.id,
    })
  );

  const {
    response: wishlists,
    setResponse: setWishlists,
    loading: wishlistsLoading,
    loadNextPage,
  } = useRequest({
    url: `/wishlists/?${endpointQuery}`,
    authRequired: false,
    initialValue: [],
  });

  const loading = userLoading || wishlistsLoading;

  useInfiniteScroll(loadNextPage);

  return (
    <>
      {!userLoading && user && (
        <>
          <UserDetail user={user} sx={{ mb: 5 }} />
          <WishlistList wishlists={wishlists} setWishlists={setWishlists} />
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

const PreviewGalleryBase = ({ images, alt }) => {
  const size = 26;

  const containerSx = {
    width: theme.spacing(size),
    height: theme.spacing(size),
    m: 'auto',
  };

  const imageSx = {
    objectFit: 'cover',
    border: 1,
    borderColor: grey[300],
  };

  if (images.length === 0) {
    return (
      <Box sx={containerSx}>
        <Placeholder
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            ...imageSx,
          }}
        />
      </Box>
    );
  }

  if (images.length === 1) {
    return (
      <Box sx={containerSx}>
        <Image
          src={images[0]?.content}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            ...imageSx,
          }}
        />
      </Box>
    );
  }

  if (images.length === 2) {
    const loop = Array.apply(0, Array(2)).map((x, i) => i);

    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          ...containerSx,
        }}
      >
        {loop.map((i) => {
          return (
            <Image
              key={i}
              src={images[i]?.content}
              alt={alt}
              sx={{
                width: theme.spacing(size / 2),
                height: theme.spacing(size),
                ...imageSx,
              }}
            />
          );
        })}
      </Box>
    );
  }

  if (images.length === 3) {
    const loop = Array.apply(0, Array(3)).map((x, i) => i);
    const areas = ['a', 'b', 'c'];

    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateAreas: '"a b" "c b"',
          gridAutoRows: 'auto',
          ...containerSx,
        }}
      >
        {loop.map((i) => {
          return (
            <Image
              key={i}
              src={images[i]?.content}
              alt={alt}
              sx={{
                width: theme.spacing(size / 2),
                height: i === 1 ? theme.spacing(size) : theme.spacing(size / 2),
                gridArea: areas[i],
                ...imageSx,
              }}
            />
          );
        })}
      </Box>
    );
  }

  const loop = Array.apply(0, Array(4)).map((x, i) => i);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        ...containerSx,
      }}
    >
      {loop.map((i) => {
        return (
          <Image
            key={i}
            src={images[i]?.content}
            alt={alt}
            sx={{
              width: theme.spacing(size / 2),
              height: theme.spacing(size / 2),
              ...imageSx,
            }}
          />
        );
      })}
    </Box>
  );
};

const PreviewGallery = memo(
  PreviewGalleryBase,
  (prevProps, nextProps) =>
    prevProps.images.slice(0, 4).map((image) => image.id) ===
    nextProps.images.slice(0, 4).map((image) => image.id)
);

const UpdateWishlist = ({ wishlist, setWishlist, isOpen, close }) => {
  const { t } = useTranslation();

  const request = useRequest({
    url: `/wishlists/${wishlist.id}/`,
    method: 'PATCH',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const fields = [
    {
      id: 'name',
      type: 'text',
      placeholder: t('name'),
      autoFocus: true,
    },
    {
      id: 'visibility',
      type: 'radio',
      value: 'public',
      options: [
        {
          value: 'public',
          label: t('Public'),
        },
        {
          value: 'private',
          label: t('Private'),
        },
      ],
    },
  ];

  useEffect(() => {
    if (request.response) {
      setWishlist(request.response);
      close();
    }
  }, [request.response, setWishlist, close]);

  return (
    <FormDialog
      title={t('Edit Wishlist')}
      fields={fields}
      request={request}
      isOpen={isOpen}
      close={close}
      value={wishlist}
      submitLabel={t('Save')}
    />
  );
};

const DeleteWishlist = ({ wishlist, setWishlists, isOpen, close }) => {
  const { t } = useTranslation();

  const request = useRequest({
    url: `/wishlists/${wishlist.id}/`,
    method: 'DELETE',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const performDelete = () => {
    request.trigger();
  };

  useEffect(() => {
    if (request.responseStatus) {
      setWishlists((prevWishlists) => {
        return prevWishlists.filter((x) => x.id !== wishlist.id);
      });
      close();
    }
  }, [request.responseStatus, setWishlists, wishlist.id, close]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="confirm-deletion-title"
    >
      <DialogTitle id="confirm-deletion-title">
        {t('Deletion confirmation')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Are you sure you want to delete this Wishlist? This action can't be undone."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={close}>
          {t('Cancel')}
        </Button>
        <Button onClick={performDelete}>{t('Delete')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const WishlistCardMenu = ({ wishlist, setWishlist, setWishlists }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    isOpen: isOpenUpdateDialog,
    open: openUpdateDialog,
    close: closeUpdateDialog,
  } = useDialogState();

  const {
    isOpen: isOpenDeleteDialog,
    open: openDeleteDialog,
    close: closeDeleteDialog,
  } = useDialogState();

  const handleUpdateClick = () => {
    handleClose();
    openUpdateDialog();
  };

  const handleDeleteClick = () => {
    handleClose();
    openDeleteDialog();
  };

  return (
    <>
      <IconButton
        id="wishlist-card-options"
        aria-controls="wishlist-options"
        aria-label={t('wishlist options')}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="wishlist-options"
        MenuListProps={{
          'aria-labelledby': 'wishlist-card-options',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleUpdateClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Update')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>

      <UpdateWishlist
        wishlist={wishlist}
        setWishlist={setWishlist}
        isOpen={isOpenUpdateDialog}
        close={closeUpdateDialog}
      />
      <DeleteWishlist
        wishlist={wishlist}
        setWishlists={setWishlists}
        isOpen={isOpenDeleteDialog}
        close={closeDeleteDialog}
      />
    </>
  );
};

const WishlistListItemBase = ({ wishlist, setWishlist, setWishlists }) => {
  const auth = useAuth();

  const images = [].concat.apply(
    [],
    wishlist.gifts.map((x) => x['images'])
  );

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
        component={RouterLink}
        to={`/wishlists/${wishlist.id}/${slugify(wishlist.name)}/`}
      >
        <CardHeader
          avatar={
            <Visibility
              visibility={wishlist.visibility}
              sx={{ fontSize: theme.typography.pxToRem(30) }}
            />
          }
          title={wishlist.name}
          subheader={`${wishlist.gifts.length} items`}
        />
        <CardMedia
          component={() => (
            <Container>
              <PreviewGallery images={images} alt={wishlist.name} />
            </Container>
          )}
        />
      </CardActionArea>

      <CardActions disableSpacing>
        <Box sx={{ mx: 'auto' }} />

        {auth.isAuthUserId(wishlist.owner) && (
          <WishlistCardMenu
            wishlist={wishlist}
            setWishlist={setWishlist}
            setWishlists={setWishlists}
          />
        )}
      </CardActions>
    </Card>
  );
};

const WishlistListItem = memo(
  WishlistListItemBase,
  (prevProps, nextProps) =>
    prevProps.wishlist.id === nextProps.wishlist.id &&
    prevProps.wishlist.name === nextProps.wishlist.name &&
    prevProps.wishlist.visibility === nextProps.wishlist.visibility
);

const WishlistList = ({ wishlists, setWishlists }) => {
  const setWishlist = (wishlist) => {
    setWishlists((prevWishlists) => {
      return prevWishlists.map((x) => (x.id === wishlist.id ? wishlist : x));
    });
  };

  return (
    <Grid>
      {wishlists.map((wishlist) => {
        return (
          <WishlistListItem
            key={wishlist.id}
            wishlist={wishlist}
            setWishlist={setWishlist}
            setWishlists={setWishlists}
          />
        );
      })}
    </Grid>
  );
};

const ClosableDialog = ({ children, open, close }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xl"
    >
      <IconButton
        onClick={close}
        aria-label={t('Close')}
        sx={{
          position: 'absolute',
          right: 0,
          color: 'primary.main',
          backgroundColor: 'primary.contrastText',
          ml: 'auto',
          mt: 1,
          mr: 1,
        }}
      >
        <CloseIcon sx={{ fontSize: (t) => t.spacing(4) }} />
      </IconButton>
      {children}
    </Dialog>
  );
};

const findPrice = (url, form) => {
  return new Promise((resolve, reject) => {
    const connection = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/find-price`
    );

    connection.onopen = () => {
      connection.send(JSON.stringify({ url: url }));
    };

    connection.onmessage = (message) => {
      const result = JSON.parse(message.data);

      form.setValue((value) => {
        return {
          ...value,
          price: result.price,
          price_currency: result.price_currency,
        };
      });

      connection.close();
      resolve();
    };
  });
};

const findImage = (url, form) => {
  return new Promise((resolve, reject) => {
    const connection = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/find-image`
    );

    connection.onopen = () => {
      connection.send(JSON.stringify({ url: url }));
    };

    connection.onmessage = (message) => {
      const result = JSON.parse(message.data);

      form.setValue((value) => {
        return { ...value, image: result.result };
      });

      connection.close();
      resolve();
    };
  });
};

const findTitle = (url, form) => {
  return new Promise((resolve, reject) => {
    const connection = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/find-title`
    );

    connection.onopen = () => {
      connection.send(JSON.stringify({ url: url }));
    };

    connection.onmessage = (message) => {
      const result = JSON.parse(message.data);

      form.setValue((value) => {
        return { ...value, name: result.result };
      });

      connection.close();
      resolve();
    };
  });
};

const onChangeUrl = (event, form, setLoading) => {
  const url = event.target.value;

  if (!url.startsWith('http')) {
    return;
  }

  setLoading(true);

  const promises = [];

  if (!form.value.name) {
    promises.push(findTitle(url, form));
  }
  if (!form.value.price) {
    promises.push(findPrice(url, form));
  }
  if (!form.value.images) {
    promises.push(findImage(url, form));
  }

  Promise.all(promises).then(() => {
    setLoading(false);
  });
};

const NewGift = ({ wishlist, setWishlist }) => {
  const { t } = useTranslation();

  const newGiftRequest = useRequest({
    url: '/gifts/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const fields = [
    {
      id: 'name',
      type: 'text',
      label: t('name'),
      autoFocus: true,
    },
    {
      id: 'price',
      type: 'text',
      label: t('price'),
      verbose: true,
      gridItemProps: { xs: 6 },
    },
    {
      id: 'price_currency',
      type: 'select',
      label: t('currency'),
      verbose: true,
      gridItemProps: { xs: 6 },
      options: [
        { value: 'USD', label: t('Dollar $') },
        { value: 'EUR', label: t('Euro €') },
        { value: 'GBP', label: t('Pound £') },
      ],
    },
    {
      id: 'image',
      type: 'image',
      label: t('image extracted from your url'),
      verbose: true,
    },
    {
      id: 'links',
      label: t('Links'),
      type: 'array',
      items: [
        {
          id: 'url',
          type: 'url',
          label: t('url'),
          onChange: onChangeUrl,
        },
      ],
    },
    {
      id: 'notes',
      type: 'text',
      label: t('notes'),
      multiline: true,
      minRows: 2,
      maxRows: 10,
    },
  ];

  const {
    response: newGiftResponse,
    resetResponse: newGiftResetResponse,
    payload: newGiftPayload,
  } = newGiftRequest;
  const { isOpen, open, close } = useDialogState();

  const addGiftRequest = useRequest({
    url: `/wishlists/${wishlist.id}/gifts/`,
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const {
    response: addGiftResponse,
    resetResponse: addGiftResetResponse,
    setPayload: addGiftSetPayload,
    trigger: addGiftTrigger,
  } = addGiftRequest;

  useEffect(() => {
    if (newGiftResponse) {
      addGiftSetPayload({ id: newGiftResponse.id });
      addGiftTrigger();
    }
  }, [newGiftResponse, addGiftSetPayload, addGiftTrigger]);

  useEffect(() => {
    if (addGiftResponse) {
      // Add preview image if we have loaded one during the form
      // preparation.
      const newGift = addGiftResponse.gifts.filter(
        (gift) => gift.id === newGiftResponse.id
      )[0];

      newGift.images.push({
        id: newGift.images.length + 1,
        content: newGiftPayload.image,
      });

      newGift.previews.push({
        id: newGift.previews.length + 1,
        content: newGiftPayload.image,
      });

      setWishlist(addGiftResponse);

      newGiftResetResponse();
      addGiftResetResponse();

      close();
    }
  }, [
    addGiftResponse,
    addGiftResetResponse,
    setWishlist,
    close,
    newGiftPayload,
    newGiftResponse,
    newGiftResetResponse,
  ]);

  const auth = useAuth();

  return (
    <>
      {auth.isAuthUserId(wishlist.owner) && (
        <NewItem onClick={open} label={t('new gift')} />
      )}
      <FormDialog
        title={t('New Gift')}
        fields={fields}
        request={newGiftRequest}
        isOpen={isOpen}
        close={close}
        submitLabel={t('Save')}
      />
    </>
  );
};

const NewWishlist = ({ addWishlist }) => {
  const { t } = useTranslation();

  const request = useRequest({
    url: '/wishlists/',
    method: 'POST',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const fields = [
    {
      id: 'name',
      type: 'text',
      label: t('name'),
      autoFocus: true,
    },
    {
      id: 'visibility',
      type: 'radio',
      value: 'public',
      options: [
        {
          value: 'public',
          label: t('Public'),
        },
        {
          value: 'private',
          label: t('Private'),
        },
      ],
    },
  ];

  const { response, resetResponse } = request;
  const { isOpen, open, close } = useDialogState();

  useEffect(() => {
    if (response) {
      addWishlist(response);
      resetResponse();
      close();
    }
  }, [response, resetResponse, close, addWishlist]);

  const auth = useAuth();

  return (
    <>
      {auth.token && <NewItem onClick={open} label={t('new wishlist')} />}
      <FormDialog
        title={t('New Wishlist')}
        fields={fields}
        request={request}
        isOpen={isOpen}
        close={close}
        submitLabel={t('Save')}
      />
    </>
  );
};

const NewItem = ({ onClick, label }) => {
  return (
    <Fab color="primary" aria-label={label} sx={{ mb: 3 }} onClick={onClick}>
      <AddIcon />
    </Fab>
  );
};

const EmptyState = ({ src, alt, children }) => {
  const imageSx = {
    mx: 'auto',
    height: {
      xs: theme.spacing(20),
      lg: theme.spacing(30),
    },
    borderColor: 'transparent',
    filter: 'contrast(50%) brightness(130%)',
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Image sx={imageSx} src={src} alt={alt} />
      <Typography
        sx={{
          mx: 'auto',
          mt: 5,
          fontSize: {
            xs: 'h5.fontSize',
            md: 'h4.fontSize',
          },
          fontWeight: 'light',
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
};

const EmptyImageList = ({ ownerId }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/image.svg" alt={t('image upload')}>
      {t('No images here yet.')}
      {auth.isAuthUserId(ownerId) && (
        <>
          <br />
          {t('Try to upload some!')}
        </>
      )}
      {'\u00A0'}
      <span role="img" aria-label={t('Camera')}>
        📷
      </span>
    </EmptyState>
  );
};

const EmptyUserSearch = () => {
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/social.svg" alt={t('social')}>
      {t("We couldn't find anyone with that name …")}
      {'\u00A0'}
      <span role="img" aria-label={t('Shrug')}>
        🤷
      </span>
    </EmptyState>
  );
};

const EmptyUserConnections = () => {
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/social.svg" alt={t('social')}>
      {t("You don't have any connections yet.")}
      <br />
      {t('Try to search for your friends!')}
      {'\u00A0'}
      <span role="img" aria-label={t('Smiling face with sunglasses')}>
        😎
      </span>
    </EmptyState>
  );
};

const EmptyWishlistSearch = () => {
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/wishlist.svg" alt={t('wishlist')}>
      {t('No Wishlists have been found.')}
      {'\u00A0'}
      <span role="img" aria-label={t('Magnifying Glass')}>
        🔍
      </span>
    </EmptyState>
  );
};

const EmptyWishlistList = () => {
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/wishlist.svg" alt={t('wishlist')}>
      {t("You don't have any Wishlists yet.")}
      <br />
      {t('Try to create one!')}
      {'\u00A0'}
      <span role="img" aria-label={t('Sparkles')}>
        ✨
      </span>
    </EmptyState>
  );
};

const EmptyOwnedWishlist = () => {
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/gift.svg" alt={t('gift')}>
      {t("You don't have any items in your Wishlist yet.")}
      <br />
      {t('Try to add some!')}
      {'\u00A0'}
      <span role="img" aria-label={t('Sparkles')}>
        ✨
      </span>
    </EmptyState>
  );
};

const EmptyNotOwnedWishlist = () => {
  const { t } = useTranslation();

  return (
    <EmptyState src="/imgs/gift.svg" alt={t('gift')}>
      {t('Sorry, this list is empty')}
      {'\u00A0'}
      <span role="img" aria-label={t('Frowning face')}>
        ☹️
      </span>
    </EmptyState>
  );
};

const WishlistsPage = () => {
  const auth = useAuth();

  const querySearch = useQuery().get('search');
  const backgroundSearch = useBackgroundQuery().get('search');
  const effectiveSearch = querySearch || backgroundSearch;

  const endpointQuery = new URLSearchParams(
    filterFalsy({
      owner: auth?.user?.id,
      search: querySearch,
    })
  );

  const {
    response: wishlists,
    setResponse: setWishlists,
    loading,
    loadNextPage,
  } = useRequest({
    url: `/wishlists/?${endpointQuery}`,
    authRequired: false,
    initialValue: [],
  });

  const addWishlist = useCallback(
    (wishlist) => {
      setWishlists((prevWishlists) => {
        return [wishlist].concat(prevWishlists);
      });
    },
    [setWishlists]
  );

  useInfiniteScroll(loadNextPage);

  const length = wishlists.length;

  return (
    <>
      <SearchForm querySearch={effectiveSearch} sx={{ mb: 5 }} />

      <NewWishlist addWishlist={addWishlist} />

      {!loading &&
        ((length === 0 && !querySearch && <EmptyWishlistList />) ||
          (length === 0 && querySearch && <EmptyWishlistSearch />) || (
            <WishlistList wishlists={wishlists} setWishlists={setWishlists} />
          ))}
      {loading && <Loader />}
    </>
  );
};

const SocialToken = () => {
  const navigate = useNavigate();
  const { signin } = useAuth();
  const query = useQuery();
  const backgroundLocation = query.get('next') || '/';

  const { response, loading, error } = useRequest({
    url: '/auth/social-token/',
    method: 'POST',
    authRequired: false,
    isPaginated: false,
    isAuth: true,
  });

  useEffect(() => {
    if (!loading && !error && response) {
      signin(response, () => {
        navigate(backgroundLocation);
      });
    }
  }, [response, loading, error, signin, navigate, backgroundLocation]);

  return <div />;
};

const ResetPasswordConfirmDialog = () => {
  const tokenParam = useQuery().get('token');
  const [uid, token] = tokenParam ? tokenParam.split(':') : [];
  const { t } = useTranslation();
  const { signin } = useAuth();
  const navigate = useNavigate();

  const initialPayload = {
    uid: uid,
    token: token,
  };

  const { response: authResponse } = useRequest({
    url: '/auth/email-verification-token/',
    method: 'POST',
    isPaginated: false,
    immediate: true,
    authRequired: false,
    isAuth: true,
    initialPayload: initialPayload,
  });

  useEffect(() => {
    if (authResponse) {
      signin(authResponse, () => {});
    }
  }, [signin, navigate, authResponse]);

  const fields = [
    {
      id: 'password',
      type: 'password',
      label: 'Password',
    },
    {
      id: 'confirm_password',
      type: 'password',
      label: 'Confirm Password',
    },
  ];

  const formRequest = useRequest({
    url: '/users/me/',
    method: 'PATCH',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (formRequest.response) {
      setDone(true);
    }
  }, [formRequest.response, navigate]);

  if (done) {
    return <Navigate to="/" />;
  }

  return (
    <AuthDialog>
      <Form
        fields={fields}
        request={formRequest}
        submitLabel={t('Save Password')}
      />
    </AuthDialog>
  );
};

const MainTabs = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const pathSegment = location.pathname.split('/').slice(0, 2).join('/');
  const currentTab = ['/wishlists', '/users'].includes(pathSegment)
    ? pathSegment
    : '/';

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <Tabs value={currentTab} aria-label={t('main menu')} centered>
          <Tab
            component={RouterLink}
            to="/"
            value="/"
            icon={<SearchIcon />}
            label={t('Home')}
            {...a11yProps(0)}
          />
          <Tab
            component={RouterLink}
            to="/users/"
            value="/users"
            icon={<PeopleIcon />}
            label={t('Users')}
            {...a11yProps(1)}
          />
          <Tab
            component={RouterLink}
            to="/wishlists/"
            value="/wishlists"
            icon={<FormatListBulletedIcon />}
            label={t('Wishlists')}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Routes>
        <Route
          path="/"
          element={
            <TabPanel value={currentTab} index="/">
              <HomePage />
            </TabPanel>
          }
        />
        <Route path="/users">
          <Route path=":id/:username" element={<UserPage />} />
          <Route
            index
            element={
              <TabPanel value={currentTab} index="/users">
                <UsersPage />
              </TabPanel>
            }
          />
        </Route>
        <Route path="/wishlists">
          <Route path=":id/:name" element={<WishlistPage />} />
          <Route
            path=":id/:name/:giftId/:giftName"
            element={<WishlistPage />}
          />
          <Route
            index
            element={
              <TabPanel value={currentTab} index="/wishlists">
                <WishlistsPage />
              </TabPanel>
            }
          />
        </Route>
        <Route path="/auth/social-token/" element={<SocialToken />} />
        <Route
          path="/auth/reset-password"
          element={<ResetPasswordConfirmDialog />}
        />
        <Route path="/login" element={<LoginDialog />} />
        <Route path="/login/email" element={<EmailLoginDialog />} />
        <Route path="/signup" element={<SignUpDialog />} />
        <Route path="/signup/email" element={<EmailSignUpDialog />} />
        <Route path="/reset-password" element={<ResetPasswordDialog />} />
        <Route path="/auth/activation" element={<Activation />} />
      </Routes>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          display: { xs: 'block', lg: 'none' },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={currentTab}
          aria-label={t('main menu')}
        >
          <BottomNavigationAction
            component={RouterLink}
            to="/"
            value="/"
            icon={<SearchIcon />}
            label={t('Home')}
            {...a11yProps(0)}
          />
          <BottomNavigationAction
            component={RouterLink}
            to="/users/"
            value="/users"
            icon={<PeopleIcon />}
            label={t('Users')}
            {...a11yProps(1)}
          />
          <BottomNavigationAction
            component={RouterLink}
            to="/wishlists/"
            value="/wishlists"
            icon={<FormatListBulletedIcon />}
            label={t('Wishlists')}
            {...a11yProps(2)}
          />
        </BottomNavigation>
      </Box>
    </>
  );
};

const localeMap = {
  en: enUsLocale,
  'en-GB': enGbLocale,
  'en-US': enUsLocale,
  de: deLocale,
  es: esLocale,
  'es-ES': esLocale,
};

function App() {
  const [locale, setLocale] = useState(i18n.languages[0]);

  i18n.on('languageChanged', (language) => {
    setLocale(language);
  });

  return (
    <Suspense fallback={<div />}>
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <ProvideAuth>
            <Router>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[locale]}
              >
                <InnerApp />
              </LocalizationProvider>
            </Router>
          </ProvideAuth>
        </I18nextProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

const AccountMenu = () => {
  const auth = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signout = () => {
    auth.signout();
    handleClose();
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label={t('account of current user')}
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircleIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!auth.token && (
          <MenuItem
            component={RouterLink}
            to="/login"
            onClick={handleClose}
            state={{ backgroundLocation: location }}
          >
            {t('Login')}
          </MenuItem>
        )}
        {!auth.token && (
          <MenuItem
            component={RouterLink}
            to="/signup"
            onClick={handleClose}
            state={{ backgroundLocation: location }}
          >
            {t('Sign Up')}
          </MenuItem>
        )}
        {auth.token && auth.user && (
          <MenuItem
            component={RouterLink}
            onClick={handleClose}
            to={`/users/${auth.user.id}/${slugify(auth.user.username)}/`}
          >
            {t('Profile')}
          </MenuItem>
        )}
        {auth.token && <MenuItem onClick={signout}>{t('Logout')}</MenuItem>}
      </Menu>
    </>
  );
};

const NOTIFICATION_CONNECTION_REQUEST = 'connection-request';

export function makeLink(notification) {
  if (notification.type === NOTIFICATION_CONNECTION_REQUEST) {
    return `/users/${notification.sender.id}/${slugify(
      notification.sender.username
    )}`;
  }

  return '';
}

export function makeMessage(notification, t) {
  if (notification.message) {
    return notification.message;
  }

  if (notification.type === NOTIFICATION_CONNECTION_REQUEST) {
    return (
      <>
        {t('{{name}} wants to connect', { name: notification.sender.username })}
      </>
    );
  }

  return '---';
}

const MaybeLink = forwardRef(({ to, ...props }, ref) => {
  if (to) {
    return <RouterLink ref={ref} to={to} {...props} />;
  } else {
    return <Card ref={ref} {...props} />;
  }
});

const NotificationsListItem = ({ notification, close, setRead }) => {
  const { t } = useTranslation();

  const { trigger } = useRequest({
    url: `/notifications/${notification.id}/read/`,
    method: 'PUT',
    isPaginated: false,
    immediate: false,
    authRequired: true,
  });

  const onClick = () => {
    trigger();
    setRead();
    close();
  };

  return (
    <Card
      sx={{
        backgroundColor: notification.read ? undefined : 'info.dark',
        color: notification.read ? undefined : 'info.contrastText',
      }}
    >
      <CardActionArea
        component={MaybeLink}
        to={makeLink(notification)}
        onClick={onClick}
      >
        <CardHeader
          title={makeMessage(notification, t)}
          sx={{
            px: {
              xs: 3,
              md: 5,
            },
            py: {
              xs: 2,
              md: 4,
            },
          }}
          titleTypographyProps={{
            sx: {
              fontSize: {
                xs: theme.typography.pxToRem(17),
                md: theme.typography.pxToRem(20),
              },
            },
          }}
        />
      </CardActionArea>
    </Card>
  );
};

const NotificationsList = ({ setHasUnread, close }) => {
  const { t } = useTranslation();

  const {
    response: notifications,
    loading,
    setResponse: setNotifications,
  } = useRequest({
    url: '/notifications/',
    initialValue: [],
    isPaginated: true,
    authRequired: true,
  });

  const defaultNotification = {
    id: -1,
    message: t("You don't have notifications"),
    read: true,
  };

  useEffect(() => {
    const unread = notifications.some((notification) => !notification.read);
    setHasUnread(unread);
  }, [notifications, setHasUnread]);

  const setRead = useCallback(
    (idx) => () => {
      setNotifications((prevNotifications) => {
        const newNotifications = [...prevNotifications];
        newNotifications[idx].read = true;
        return newNotifications;
      });
    },
    [setNotifications]
  );

  return (
    <>
      {(loading && <Loader />) ||
        (notifications.length === 0 && (
          <NotificationsListItem
            notification={defaultNotification}
            close={close}
            setRead={() => {}}
          />
        )) ||
        notifications.map((notification, idx) => (
          <NotificationsListItem
            key={notification.id}
            notification={notification}
            close={close}
            setRead={setRead(idx)}
          />
        ))}
    </>
  );
};

const Notifications = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasUnread, setHasUnread] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label={t('notifications')}
        aria-controls="menu-notifications"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {(hasUnread && (
          <Badge color="error" variant="dot">
            <NotificationsIcon />
          </Badge>
        )) || <NotificationsIcon />}
      </IconButton>

      <Menu
        id="menu-notifications"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={close}
        sx={{
          '& > div > ul': { p: 0 },
        }}
      >
        <NotificationsList setHasUnread={setHasUnread} close={close} />
      </Menu>
    </>
  );
};

function InnerApp() {
  const auth = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          sx={{ color: 'black', backgroundColor: 'white' }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Giftuki
            </Typography>

            {auth.user && <Notifications />}
            <AccountMenu />
          </Toolbar>
        </AppBar>

        <Container component="main" sx={{ pb: 10 }}>
          <Toolbar />

          <MainTabs />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
