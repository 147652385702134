export function slugify(string) {
  if (!string) {
    return string;
  }

  return string
    .normalize('NFKD')
    .replace(/[^\w\s-]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[-\s]+/g, '-');
}

export function getDomain(url) {
  if (!url || !url.includes('.')) {
    return url;
  }

  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url;
  }

  try {
    return new URL(url).hostname.replace('www.', '');
  } catch (e) {
    return url;
  }
}
