import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export function Logo(props) {
  const theme = useTheme();

  const primary = theme.palette.primary;

  const fillColor = props.fillColor || primary.main;
  const strokeColor = props.strokeColor || grey['500'];
  const strokeWidth = props.strokeWidth || 1;
  const red = props.red || primary.main;
  const black = props.black || primary.main;
  const white = props.white || primary.main;
  const bowColor = props.bowColor || primary.dark;
  const transparent = 'transparent';

  return (
    <Box
      component="svg"
      role="img"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2 0 148 130"
      {...props}
    >
      <title>Giftuki</title>
      <g id="layer1" transform="translate(0,-175.29167)">
        <ellipse
          style={{
            fill: transparent,
            strokeWidth: strokeWidth,
            stroke: transparent,
            strokeOpacity: 0,
            fillOpacity: 0,
          }}
          id="path193-0"
          cx="91.517265"
          cy="284.45535"
          rx="16.601048"
          ry="9.5349607"
        />
        <ellipse
          style={{
            fill: transparent,
            strokeWidth: strokeWidth,
            stroke: transparent,
            strokeOpacity: 0,
            fillOpacity: 0,
          }}
          id="path193"
          cx="56.168076"
          cy="284.52884"
          rx="16.601048"
          ry="9.5349607"
        />
        <path
          style={{
            fill: transparent,
            stroke: transparent,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 0,
            fillOpacity: 0,
          }}
          d="m 110.96802,240.40809 c 7.79846,0.38303 17.48296,-0.6717 30.44185,-5.69825 -7.26773,10.34133 -16.01528,15.61604 -26.01698,18.41897 -1.47495,-4.24024 -2.94992,-8.48048 -4.42487,-12.72072 z"
          id="path174-7"
        />
        <path
          style={{
            fill: transparent,
            stroke: transparent,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 0,
            fillOpacity: 0,
          }}
          d="m 34.172035,239.63339 c -7.378243,2.55401 -16.969963,4.25668 -30.8183631,3.06481 9.8754971,7.88905 19.7509981,10.49981 30.1372951,10.3863 0.227023,-4.4837 0.454045,-8.96741 0.681068,-13.45111 z"
          id="path174"
        />
        <path
          style={{
            fill: fillColor,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 25.929174,217.88966 c 35.64056,-11.97176 67.890992,-8.63526 97.102146,-1.32292 -11.64167,21.25486 -7.9375,53.09305 -1.85208,66.41041 -33.161178,-10.14236 -56.003539,-8.90764 -95.51465,0.26459 9.172222,-15.9632 6.967361,-48.33056 0.264584,-65.35208 z"
          id="path99"
        />
        <circle
          style={{ fill: black, strokeWidth: strokeWidth }}
          id="path172"
          cx="54.760975"
          cy="235.54182"
          r="13.451106"
        />
        <circle
          style={{ fill: white, strokeWidth: strokeWidth }}
          id="path180"
          cx="50.016037"
          cy="230.56027"
          r="5.7932591"
        />
        <rect
          style={{ fill: black, strokeWidth: strokeWidth }}
          id="rect92"
          width="27.583279"
          height="10.726835"
          x="60.338081"
          y="257.28098"
          rx="8.2218132"
          ry="5.1080151"
        />
        <rect
          style={{ fill: black, strokeWidth: strokeWidth }}
          id="rect92-5"
          width="27.583279"
          height="5.1080165"
          x="60.338074"
          y="257.28098"
          rx="0"
          ry="0"
        />
        <ellipse
          style={{ fill: red, fillRule: 'evenodd', strokeWidth: strokeWidth }}
          id="path82"
          cx="70.814713"
          cy="263.85678"
          rx="4.6719079"
          ry="3.6207283"
        />
        <path
          style={{
            fill: bowColor,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 62.157282,199.729 c -6.461942,-10.38618 -16.87677,-11.92894 -33.32717,-12.31891 -6.448521,12.99748 -6.191114,19.5564 1.401572,30.93222 13.543146,-0.84043 27.660889,-3.81321 31.904687,-13.70781 z"
          id="path206"
        />
        <path
          style={{
            fill: bowColor,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 86.092605,199.61724 c 6.46194,-10.38618 17.089455,-11.92894 33.539855,-12.31891 7.66493,5.85237 4.80108,26.15383 -1.40157,30.93222 -13.54315,-0.84043 -27.903959,-3.81321 -32.147759,-13.70781 z"
          id="path206-7"
        />
        <path
          style={{
            fill: red,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 62.369966,205.78589 c -8.629005,-2.58117 -13.637529,-1.83546 -20.789988,2.56955 6.204633,-4.48333 13.347354,-4.27622 20.789988,-2.56955 z"
          id="path231"
        />
        <path
          style={{
            fill: red,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 62.293983,201.04964 c -8.985128,0.6242 -13.707834,-1.20267 -19.718994,-7.07019 5.069257,5.73589 12.083254,7.10162 19.718994,7.07019 z"
          id="path231-9"
        />
        <path
          style={{
            fill: red,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 86.503617,205.97363 c 8.629005,-2.58117 13.637533,-1.83546 20.790023,2.56955 -6.20466,-4.48333 -13.347389,-4.27622 -20.790023,-2.56955 z"
          id="path231-3"
        />
        <path
          style={{
            fill: red,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 86.5796,201.23738 c 8.985128,0.6242 13.70783,-1.20267 19.71903,-7.07019 -5.06929,5.73589 -12.08329,7.10162 -19.71903,7.07019 z"
          id="path231-9-6"
        />
        <rect
          style={{
            fill: bowColor,
            fillOpacity: 1,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeOpacity: 1,
          }}
          id="rect204"
          width="26.114695"
          height="15.176708"
          x="61.165474"
          y="195.38924"
          rx="8.9609222"
          ry="3.2688231"
        />
        <circle
          style={{ fill: white, strokeWidth: strokeWidth }}
          id="path180-0"
          cx="57.05925"
          cy="237.49515"
          r="3.2237098"
        />
        <circle
          style={{ fill: black, strokeWidth: strokeWidth }}
          id="path172-6"
          cx="95.464226"
          cy="235.34995"
          r="13.451106"
        />
        <circle
          style={{ fill: white, strokeWidth: strokeWidth }}
          id="path180-1"
          cx="90.719292"
          cy="230.36839"
          r="5.7932591"
        />
        <circle
          style={{ fill: white, strokeWidth: strokeWidth }}
          id="path180-0-8"
          cx="97.762505"
          cy="237.30327"
          r="3.2237098"
        />
        <ellipse
          style={{ fill: red, fillRule: 'evenodd', strokeWidth: strokeWidth }}
          id="path82-7"
          cx="77.433624"
          cy="263.84265"
          rx="4.6719079"
          ry="3.6207285"
        />
        <rect
          style={{
            fill: red,
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: strokeWidth,
            strokeOpacity: 1,
          }}
          id="rect1198"
          width="6.3384047"
          height="3.373667"
          x="70.949242"
          y="264.09067"
        />
        <path
          style={{
            fill: fillColor,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 12.67951,200.33373 c 32.367365,4.7625 94.36809,6.08541 127.52922,0 -11.64167,4.58611 -10.84792,16.85012 -1.85208,21.70082 -32.03358,-7.94755 -94.703673,-7.45051 -125.412558,-0.52918 5.997226,-5.11528 8.819449,-16.05637 -0.264582,-21.17164 z"
          id="path99-3"
        />
      </g>
    </Box>
  );
}
